import { ReactNode } from 'react';

import {
  ItemCardCenter,
  ItemCardIcon,
  ItemCardIconProps,
  ItemCardSubtitle,
  ItemCardTitle,
  ListItemCard
} from './listItemCard.styles';

type Props = {
  title?: ReactNode;
  subtitle?: ReactNode;
  invalid?: boolean;
  children?: ReactNode;
  className?: string;
  dataTestId?: string;
} & ItemCardIconProps;

const ListItem = ({ title, subtitle, icon, content, invalid, avatarTheme, children, className }: Props) => {
  return (
    <ListItemCard data-testid="list-item-card" className={className}>
      <ItemCardIcon icon={icon} content={content} avatarTheme={avatarTheme} />
      <ItemCardCenter>
        <ItemCardTitle>{title}</ItemCardTitle>
        <ItemCardSubtitle invalid={invalid}>{subtitle}</ItemCardSubtitle>
      </ItemCardCenter>
      {children}
    </ListItemCard>
  );
};

export default ListItem;
