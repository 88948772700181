import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Field, PhoneField, Modal, Loader } from 'components';
import { PhoneVerificationModal } from 'features/users/components';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { UserData } from 'services/RbacService/typings';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import useUpdatePhoneNumber from '../crudUserDrawer/hooks/useUpdatePhoneNumber';

import { PhoneInputWrapper } from './editPhoneModal.styles';

type Props = {
  onVisibilityChange?: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  userData?: UserData;
};

const validationSchema = Yup.object({
  phoneNumber: Yup.string()
    .min(11, VALIDATION_MESSAGE_ID.PHONE_NUMBER_LENGTH)
    .max(12, VALIDATION_MESSAGE_ID.PHONE_NUMBER_LENGTH)
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
});

export const EditPhoneModal: FC<Props> = ({ isOpen, onVisibilityChange, userData }) => {
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState(userData?.mobilePhoneNumber || '');
  const [creationStep, setCreationStep] = useState<'provideInfo' | 'verifyCreation'>('provideInfo');

  const formValues = useMemo(
    () => ({
      phoneNumber: userData?.mobilePhoneNumber || ''
    }),
    [userData?.mobilePhoneNumber]
  );

  const { validationHelpers, getValues, handleSubmit, setTouchedOnAll, reset } = useFormHandlers(
    formValues,
    validationSchema
  );

  const { updatePhoneNumber, isLoading } = useUpdatePhoneNumber(userData?.userId);

  const handleClose = () => {
    reset({ phoneNumber: userData?.mobilePhoneNumber || '' });
    setPhoneNumber(userData?.mobilePhoneNumber || '');
    setCreationStep('provideInfo');
    onVisibilityChange?.(false);
  };

  const handlePhoneUpdate = async () => {
    if (phoneNumber && userData?.userId) {
      try {
        await updatePhoneNumber(phoneNumber);
      } finally {
        handleClose();
      }
    }
  };

  const handleSave = () => {
    handleSubmit(
      (payload) => {
        setPhoneNumber(payload.phoneNumber);
        setCreationStep('verifyCreation');
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  return (
    <>
      {isLoading ? <Loader opaque /> : null}
      <PhoneVerificationModal
        phoneNumber={phoneNumber}
        isOpen={isOpen && creationStep === 'verifyCreation'}
        onClose={handleClose}
        onSuccess={handlePhoneUpdate}
      />
      <Modal
        isOpen={isOpen && creationStep === 'provideInfo'}
        label={t('phone')}
        onClose={handleClose}
        secondaryActionLabel={t('cancel')}
        mainActionLabel={t('save')}
        mainActionhandler={handleSave}
      >
        <PhoneInputWrapper>
          <Field
            Component={PhoneField}
            name="phoneNumber"
            value={getValues('phoneNumber')}
            placeholder={t('phone')}
            defaultCountry={'LT'}
            validationHelpers={validationHelpers}
            required
          />
        </PhoneInputWrapper>
      </Modal>
    </>
  );
};
