import { useTranslation } from 'react-i18next';

import Typography from 'components/typography/typography';
import { Drawer } from 'features/app/components/drawer';
import { STEPS } from 'features/application/types/applicationTypes';
import { useNavContext } from 'features/nav/context/navContext';

export type StepExplanationDrawer = {
  step: STEPS;
  sub?: string;
};

const explanations: Record<STEPS, string | { [x: string]: string }> = {
  [STEPS.PRODUCT_TYPE]: '',
  [STEPS.SUBPRODUCT_TYPE]: '',
  [STEPS.AMOUNT]: '',
  [STEPS.COMPANY]: '',
  [STEPS.CONTACT_PERSON]: '',
  [STEPS.FACTORING_TYPE]: {
    insurancePresent: 'factoringInsolvencyRisk',
    insuranceLimitFee: 'factoringBuyerInsuranceExplanation'
  },
  [STEPS.LOAN]: '',
  [STEPS.BENEFICIAL_OWNERS]: 'beneficialOwnerInfoExplanation',
  [STEPS.THIRD_PARTIES]: '',
  [STEPS.TERMS]: '',
  [STEPS.PURPOSE]: '',
  [STEPS.BANK_ACCOUNTS]: '',
  [STEPS.REVIEW]: '',
  [STEPS.PRICE_AND_PAYMENT_DETAILS]: '',
  [STEPS.SUCCESS_SUBMIT]: '',
  [STEPS.REGISTRATION_COUNTRY]: ''
};

const StepExplanation = () => {
  const { t } = useTranslation();
  const { hideSideDrawer, activeDrawer } = useNavContext<StepExplanationDrawer>();

  const explanationId = explanations[activeDrawer?.step ?? STEPS.PRODUCT_TYPE];

  return (
    <Drawer isValid onSave={async () => hideSideDrawer?.()} buttonLabel={t('gotIt')}>
      <Typography.Body>
        {t(typeof explanationId === 'string' ? explanationId : explanationId[activeDrawer?.sub ?? ''])}
      </Typography.Body>
    </Drawer>
  );
};

export default StepExplanation;
