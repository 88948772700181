import styled from 'styled-components';

import { LinkButtonStyle } from 'components';

export const Wrapper = styled.div`
  span {
    text-align: center;
    vertical-align: top;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    display: flex;
    justify-content: space-between;
  }

  ${LinkButtonStyle} {
    svg path {
      stroke: ${({ theme }) => theme.colors.primary.linkText};
    }
  }
`;
