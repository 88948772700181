import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import chevronRight from 'assets/icons/chevronRight.svg';
import { LinkButton, RegularText } from 'components';
import { GeneralTermsAndConditionsRepresentation } from 'features/app/components/generalTermsAndConditions/generalTermsAndConditions';
import { selectIsRegularUser } from 'features/auth/selectors';
import { useAuthSelector } from 'hooks/redux/hooks';

import { ContentContainer, ButtonContainer } from './documents.styles';

const Documents = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const isRegularUser = useAuthSelector<typeof selectIsRegularUser>(selectIsRegularUser);

  const showCookieSettings = () => {
    window.Cookiebot.renew();
  };

  const onToggleModal = () => () => {
    setOpenModal(true);
  };

  const isVerified = isRegularUser;

  return (
    <>
      <GeneralTermsAndConditionsRepresentation<'view'>
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
      <ContentContainer>
        <ButtonContainer>
          <a target="blank" href={'https://www.smefinance.eu/en/privacy-policy'} rel="noopener">
            {t('privacyPolicy')}
            <Icons.ChevronRight />
          </a>
        </ButtonContainer>

        {isVerified ? (
          <ButtonContainer>
            <LinkButton onClick={onToggleModal()} icons={{ right: chevronRight }} color="Grey">
              <RegularText>{t('termsAndConditions')}</RegularText>
            </LinkButton>
          </ButtonContainer>
        ) : null}

        <ButtonContainer>
          <a target="blank" href={'https://www.smefinance.eu/en/cookies-policy'} rel="noopener">
            {t('cookiesPolicy')}
            <Icons.ChevronRight />
          </a>
        </ButtonContainer>

        <ButtonContainer>
          <LinkButton onClick={showCookieSettings} icons={{ right: chevronRight }} color="Grey">
            <RegularText>{t('cookieSettings')}</RegularText>
          </LinkButton>
        </ButtonContainer>
      </ContentContainer>
    </>
  );
};

export default Documents;
