import styled from 'styled-components';

import { ScreenSize } from 'components/layout';

export const PageColumn = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: center;
  height: 100dvh;
  max-height: 100dvh;
  background-color: ${({ theme }) => theme.colors.base.black.shades[5]};
`;

export const Content = styled.div`
  --spa-layout-max-offset: 8.5rem;

  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const Main = styled.main`
  --spa-layout-max-width: 38.75rem;
  --spa-layout-full-width: calc(var(--spa-layout-max-width) + var(--spa-layout-max-offset));
  --spa-layout-applied-offset: min(calc(100% - var(--spa-layout-max-width)), var(--spa-layout-max-offset));

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

type MainContentProps = {
  wide?: boolean;
};

export const MainContent = styled.div<MainContentProps>`
  max-width: ${({ wide }) => (wide ? '62.5rem' : 'var(--spa-layout-max-width)')};
  width: fill-available;
  padding-inline: 2.5rem;
  flex: 1;
  position: relative;
  margin-right: max(var(--spa-layout-applied-offset), 0px);

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-right: 0;
  }

  @media (max-width: 59.875rem) {
    max-width: 100%;
    padding-inline: 1.25rem;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    margin-bottom: 5.5rem;
  }
`;

export const FullWidthMainContent = styled(MainContent)`
  max-width: 100%;
  padding-inline: 0;
  height: calc(100dvh - var(--spa-globals-top-banner-height));
  display: flex;
  flex-direction: column;

  @media (max-width: 59.875rem) {
    max-width: 100%;
    padding-inline: 0;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    margin-bottom: 0;
  }
`;

type TopNavProps = {
  notShown?: boolean;
  position?: 'top' | 'bottom';
};

export const TopNav = styled.nav<TopNavProps>`
  visibility: ${({ notShown }) => (notShown ? 'hidden' : 'visible')};
  height: 5rem;
  position: sticky;
  top: ${({ position }) => (position === 'bottom' ? 'calc(100% - 5rem)' : 0)};
  bottom: ${({ position }) => (position === 'top' ? 'calc(100% - 5rem)' : 0)};
  width: 100%;
  z-index: ${({ theme }) => theme.layers.overlay};
  background-color: ${({ theme }) => theme.colors.base.black.shades[5]};

  &.top-shadow {
    border-bottom: 0.0625rem ${({ theme }) => theme.colors.base.black.shades[10]} solid;
  }

  &.bottom-shadow {
    border-top: 0.0625rem ${({ theme }) => theme.colors.base.black.shades[10]} solid;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    max-height: 5rem;
    height: auto;
  }
`;

export const Nav = styled.nav`
  --spa-layout-side-nav-width: calc(var(--spa-layout-max-offset) * 2);

  width: var(--spa-layout-side-nav-width);
  min-width: var(--spa-layout-side-nav-width);
  display: flex;
  position: sticky;
  top: 0;
  height: calc(100dvh - var(--spa-globals-top-banner-height));
  overflow: auto;
  overscroll-behavior: contain;
  border-right: 0.0625rem ${({ theme }) => theme.colors.base.black.shades[10]} solid;

  @media ${({ theme }) => theme.media.maxTablet} {
    width: 100%;
    position: sticky;
    z-index: ${({ theme }) => theme.layers.nav};
    height: auto;
    overflow: hidden;
  }
`;

export type AsideContentProps = {
  hideScroll?: boolean;
};

export const AsideContent = styled.div<AsideContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.layers.modal};
  overflow: ${({ hideScroll }) => (hideScroll ? 'hidden' : 'auto')};

  @media ${({ theme }) => theme.media.maxTablet} {
    max-height: calc(100dvh - 1.5rem);
    height: auto;
  }
`;

type AsideProps = {
  position?: 'absolute' | 'sticky' | 'fixed';
  absolute?: boolean;
  animationDurationInMs?: number;
  opacity?: number;
};

export const Aside = styled.aside<AsideProps>`
  --aside-slide-duration: ${({ animationDurationInMs }) => `${animationDurationInMs ?? 0}ms`};

  display: flex;
  flex: 1 0 100%;
  max-width: 0;
  position: ${({ position }) => position};
  height: calc(100dvh - var(--spa-globals-top-banner-height));
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0.25rem 1.25rem 0 #00000010;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  z-index: ${({ theme }) => theme.layers.sideDrawer};
  overscroll-behavior: contain;
  top: 0;
  opacity: ${({ opacity }) => opacity ?? 0};
  transform: translateX(25rem);
  right: ${({ position }) => (position === 'absolute' || position === 'fixed' ? '-25rem' : 'unset')};
  width: ${({ position }) => (position === 'absolute' || position === 'fixed' ? '25rem' : 'initial')};

  > ${AsideContent} {
    display: flex;
    position: fixed;
    top: 0;
    width: 25rem;
  }

  @media ${({ theme }) => theme.media.maxDesktop} {
    position: fixed;
    overflow: auto;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    height: auto;
    width: 100%;
    transform: translateX(0);
    transform: translateY(100%);

    > ${AsideContent} {
      position: relative;
      width: 100%;
    }
  }

  &.open {
    transition:
      opacity var(--aside-slide-duration) ease-out,
      max-width var(--aside-slide-duration) ease-out,
      transform var(--aside-slide-duration) ease-out;
    height: calc(100dvh - var(--spa-globals-top-banner-height));
    top: 0;
    position: ${({ position }) => position};
    transform: translateX(0);
    max-width: 25rem;
    opacity: 1;
    right: ${({ position }) => (position === 'absolute' || position === 'fixed' ? '0' : 'unset')};

    @media ${({ theme }) => theme.media.maxDesktop} {
      transition:
        opacity var(--aside-slide-duration) ease-out,
        transform var(--aside-slide-duration) ease-out;
      transform: translateY(0);
      max-height: calc(100dvh - 1.5rem);
      height: auto;
      bottom: 0;
      top: auto;
      width: 100dvw;
      max-width: 100dvw;
      position: fixed;
      overflow: auto;
    }
  }

  &.close:not([data-swipe-confirm='true']) {
    transition:
      opacity var(--aside-slide-duration) ease-out,
      max-width var(--aside-slide-duration) ease-out,
      transform var(--aside-slide-duration) ease-out;
    width: 100%;
    max-width: 0;
    transform: translateX(0);
    opacity: 0;
    right: ${({ position }) => (position === 'absolute' || position === 'fixed' ? '0' : 'unset')};

    @media ${({ theme }) => theme.media.maxDesktop} {
      transition:
        opacity var(--aside-slide-duration) ease-out,
        transform var(--aside-slide-duration) ease-out;
      transform: translateY(100%);
      max-height: calc(100dvh - 1.5rem);
      height: auto;
      bottom: 0;
      top: auto;
      width: 100dvw;
      max-width: 100dvw;
      position: fixed;
      overflow: auto;
    }
  }

  &.drawer-transition {
    > ${AsideContent} {
      overflow: hidden !important;
    }

    @media ${({ theme }) => theme.media.maxDesktop} {
      overflow: hidden !important;
    }
  }
`;

export const Overlay = styled.div<AsideProps>`
  display: ${({ position }) => (position === 'absolute' ? 'block' : 'none')};
  visibility: hidden;
  z-index: ${({ theme }) => theme.layers.sideDrawer};
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 50%);
  opacity: 0;
  transition: opacity 0.15s ease-in;

  &:has(+ ${Aside}.open) {
    visibility: visible;
    opacity: 1;
  }

  &:has(+ ${Aside}.close) {
    visibility: visible;
    opacity: 0;
  }

  @media ${({ theme }) => theme.media.maxDesktop} {
    display: block;
  }
`;

export const MainContainer = styled.div<AsideProps>`
  width: 100%;
  max-width: 100%;
  flex: 1;
  display: flex;

  @media ${({ theme }) => theme.media.maxTablet} {
    flex-direction: column;
  }
`;

export const BottomDrawerScreen = styled(ScreenSize)`
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 100%;
  align-items: end;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 50%);
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: opacity 0.15s ease-out;
`;

type BottomDrawerChildrenContainerProps = {
  height: number;
  animate: boolean;
};

export const BottomDrawerChildrenContainer = styled.div<BottomDrawerChildrenContainerProps>`
  position: absolute;
  display: flex;
  bottom: ${({ hidden, height }) => (hidden ? `-${height}px` : 0)};
  transition: ${({ animate }) => (animate ? 'bottom 0.1s ease-out' : 'none')};
  width: 100%;
`;

export const OverlayViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.layers.overlay};
  background-color: ${({ theme }) => theme.colors.base.black.shades[5]};

  &.lower-z-index {
    z-index: ${({ theme }) => theme.layers.nav};
  }
`;

export const OverlayViewContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem 1rem;
  height: 100%;
  overflow: auto;
`;

export const Pill = styled.div`
  position: absolute;
  content: '';
  display: none;
  width: 3.5rem;
  height: 0.25rem;
  background-color: ${({ theme }) => theme.colors.base.black.shades[10]};
  border-radius: 6.25rem;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;

  @media ${({ theme }) => theme.media.maxTablet} {
    display: flex;
  }
`;

export const TitleAndActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    align-items: flex-start;
  }
`;

export const TitleAndActionsInnerRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

export const DrawerTopbarTitle = styled.span`
  font-size: 1rem;
  line-height: 1.375rem;
  display: inline-flex;
  flex: 1;
  justify-content: center;
  margin-right: 2.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const WideLayoutPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary.background};
  margin-top: 1.5rem;
  height: 100%;
  padding-inline: 1rem;
`;

export const IsolatedBottomNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.75rem 1rem 1.5rem;

  button {
    width: 100%;
  }
`;
