import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Toast } from 'components';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { getOnboardingSSO } from 'defaultConfiguration/getOnboardingSSO';
import { OfferSubmittedModal } from 'features/applications/components';
import OpenBankExplanationDrawer from 'features/applications/components/openBankExplanationDrawer/openBankExplanationDrawer';
import { Status } from 'features/applications/types/applicationsTypes';
import { useNavContext } from 'features/nav/context/navContext';
import { useFetchBankAccountInfoQuery } from 'features/onboarding/api';
import { stepperSteps } from 'features/onboarding/consts';
import { useGetOnboardingEnabledConfig } from 'features/onboarding/hooks';
import { isReadyForOndato } from 'features/onboarding/hooks/useCurrentFlowStep';
import { CurrentStepStatus, Steps, isStepEnabled } from 'features/onboarding/hooks/useGetCurrentCAPStepState';
import { ActiveDrawer } from 'pages/dashboard/dashboard';
import { ApplicationItem } from 'services/CapService/types';
import { Status as OnboardingStatuses, OndatoStatusResponse } from 'services/OnboardingService/types';

import BankStepCard from '../stepCard/bankStepCard';
import CapStepCard, { isStepCompleted } from '../stepCard/capStepCard';
import ContractSigningStepCard from '../stepCard/contractSigningStepCard';
import OnboardingLoginStepCard from '../stepCard/onboardingLoginCard';
import OnboardingStepCard from '../stepCard/onboardingStepCard';

import { StepperContainer } from './stepper.styles';

type Props = {
  currentStep: Steps | null;
  currentStepStatus: Record<Steps, CurrentStepStatus> | null;
  application: ApplicationItem;
  applicationsLoading?: boolean;
  ondatoInfo: OndatoStatusResponse;
};

const unsupportedSteps = ['verifyYourBusiness', 'verifyYourIdentity', 'singContractAndReceiveFinancing'];

const Stepper = ({ application, applicationsLoading, ondatoInfo, currentStep, currentStepStatus }: Props) => {
  const { t } = useTranslation();

  const { isFeatureEnabled } = useRemoteConfigContext();
  const { toggleRightSideDrawer } = useNavContext<ActiveDrawer>();

  const { ondatoAvailableCAPStatus } = useGetOnboardingEnabledConfig();

  const { status } = application ?? { status: 'NOT_FILLED' };

  const [isOfferSubmitted, setIsOfferSubmitted] = useState(status === 'OFFER_SENT');
  const [isOpenBankAccountExplanationDrawerOpen, setIsOpenBankAccountExplanationDrawerOpen] = useState(false);

  const isOnboardingSSOEnabled = isFeatureEnabled('onboardingSSO');

  const onboardingSSOCountriesList = getOnboardingSSO();
  const enableOnboardingSSO = onboardingSSOCountriesList.includes(application?.companyCountry ?? 'LT');

  const filteredSteps = stepperSteps.filter((step) =>
    enableOnboardingSSO ? true : !unsupportedSteps.includes(step)
  );

  const showToastMessage =
    !enableOnboardingSSO && isStepCompleted('acceptOffer', status as Status, ondatoAvailableCAPStatus);

  const statusNotReadyForOndato = !isReadyForOndato(
    application?.status ?? 'INITIAL',
    ondatoAvailableCAPStatus
  );

  const notReadyForOndato = !application.applicationId || statusNotReadyForOndato;

  const { currentData: bankAccountInfo } = useFetchBankAccountInfoQuery(
    { applicationId: application.applicationId ?? '' },
    { skip: notReadyForOndato }
  );

  const showApplicationReviewInProgressDrawer = status === 'ASSESSMENT_ONGOING';

  const onInactiveClick = showApplicationReviewInProgressDrawer
    ? () => toggleRightSideDrawer?.({ drawer: 'reviewInProgress', id: application.capId })
    : undefined;

  useEffect(() => {
    setIsOfferSubmitted(status === 'OFFER_SENT');
  }, [status]);

  const isOfferAccepted = isStepCompleted('acceptOffer', status as Status, 'OFFER_ACCEPTED');

  const isOfferStepCompleted =
    isStepCompleted('acceptOffer', status as Status, 'OFFER_ACCEPTED') || status !== 'OFFER_SENT';

  const contractStepCardEnabled =
    isOfferAccepted && currentStepStatus?.verifyYourBusiness === 'SME_CUSTOMER_ONBOARDING_COMPLETED';

  const onboardingQuestionaireInReviewOrDone =
    ['SME_CUSTOMER_ONBOARDING_WAITING', 'SME_CUSTOMER_ONBOARDING_COMPLETED'].includes(
      currentStepStatus?.verifyYourBusiness ?? ''
    ) ||
    (!enableOnboardingSSO && currentStep === 'verifyYourBusiness');

  return (
    <>
      <OfferSubmittedModal
        isOpen={isOfferSubmitted}
        onVisibilityChange={() => setIsOfferSubmitted(false)}
        applicationId={application.applicationId ?? ''}
      />
      <OpenBankExplanationDrawer
        open={isOpenBankAccountExplanationDrawerOpen}
        onClose={() => setIsOpenBankAccountExplanationDrawerOpen(false)}
      />
      <StepperContainer>
        <Toast
          variant="info"
          isVisible={showToastMessage && Boolean(bankAccountInfo?.iban)}
          header={t('youDidAllSteps')}
          message={t('youDidAllStepsMessage')}
        />
        {filteredSteps.map((step) => {
          switch (step) {
            case 'requestForFinancing':
            case 'acceptOffer':
              return (
                <CapStepCard
                  onInactiveClick={onInactiveClick}
                  loading={applicationsLoading}
                  disabled={applicationsLoading}
                  step={step}
                  key={step}
                  id={application?.capId ?? ''}
                  applicationId={application?.applicationId ?? ''}
                  status={status ?? 'NOT_FILLED'}
                />
              );
            case 'verifyYourIdentity':
              return isOnboardingSSOEnabled ? (
                <OnboardingStepCard
                  secondaryAction={!isOfferStepCompleted}
                  onInactiveClick={onInactiveClick}
                  loading={applicationsLoading}
                  ondatoInfo={ondatoInfo}
                  step={step}
                  key={step}
                  disabled={statusNotReadyForOndato || applicationsLoading}
                  status={currentStepStatus?.[step] as OnboardingStatuses}
                />
              ) : null;
            case 'verifyYourBusiness':
              return isOnboardingSSOEnabled ? (
                <OnboardingStepCard
                  secondaryAction={!isOfferStepCompleted}
                  onInactiveClick={onInactiveClick}
                  loading={applicationsLoading}
                  ondatoInfo={ondatoInfo}
                  step={step}
                  key={step}
                  disabled={!isStepEnabled(currentStep ?? 'requestForFinancing', step) || applicationsLoading}
                  status={currentStepStatus?.[step] as OnboardingStatuses}
                />
              ) : (
                <OnboardingLoginStepCard
                  loading={applicationsLoading}
                  key={step}
                  step={step}
                  status={status ?? 'INITIAL'}
                />
              );
            case 'setUpBankAccount':
              return (
                <BankStepCard
                  secondaryAction={!isOfferStepCompleted}
                  onInactiveClick={onInactiveClick}
                  alternativeBankAccountEnabled={isOnboardingSSOEnabled}
                  loading={applicationsLoading}
                  key={step}
                  step={step}
                  enabled={onboardingQuestionaireInReviewOrDone}
                  openBankAccountDrawer={() => setIsOpenBankAccountExplanationDrawerOpen(true)}
                />
              );
            case 'singContractAndReceiveFinancing':
              return (
                <ContractSigningStepCard
                  onInactiveClick={onInactiveClick}
                  loading={applicationsLoading}
                  key={step}
                  step={step}
                  enabled={contractStepCardEnabled}
                />
              );
          }
        })}
      </StepperContainer>
    </>
  );
};

export default Stepper;
