import { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { Layout } from 'components/pageV2';
import { SideNavigation } from 'features/app/components';
import { AdminBanner } from 'features/app/components/adminBanner';
import { ScrollStateContextProvider } from 'features/app/context';
import { selectIsUserGhost } from 'features/auth/selectors';
import { useMaintenanceMode } from 'features/nav/hooks';
import { MaintenanceModeCheckFunc } from 'features/nav/hooks/useMaintenanceMode';
import { useAuthSelector } from 'hooks/redux/hooks';
import { PageNotFound } from 'pages/error';
import { MaintenanceCard } from 'pages/error/maintenanceCard';

import { CreditLineBills, CreditLineContracts, CreditLineOverview } from './creditLine';
import { CreditLineTemplate } from './creditLine/creditLineTemplate';
import {
  FactoringOverview,
  FactoringBills,
  FactoringInvoices,
  FactoringStatements,
  FactoringContracts,
  InvoiceDetails
} from './factoring';
import { FactoringTemplate } from './factoring/factoringTemplate';
import { LeasingBills } from './leasing/bills';
import { LeasingContracts } from './leasing/contracts';
import { LeasingTemplate } from './leasing/leasingTemplate';
import { LeasingOverview } from './leasing/overview';
import { LoansBills, LoansContracts, LoansOverview } from './loans';
import { LoansTemplate } from './loans/loansTemplate';
import { RbfBills, RbfContracts, RbfOverview } from './rbf';
import { RbfTemplate } from './rbf/rbfTemplate';

const renderPageTemplate = (
  category: string,
  subCategory: string,
  PageContent: ReactNode | ReactNode[],
  isFeatureInMaintenance: MaintenanceModeCheckFunc
) => {
  const separateChildrenFromContracts = subCategory !== 'overview';

  switch (category) {
    case 'factoring': {
      const [isInMaintenance, , maintenanceInfo] = isFeatureInMaintenance(['factoring', 'goFinance']);

      if (isInMaintenance) {
        return <MaintenanceCard {...maintenanceInfo} />;
      }

      return (
        <FactoringTemplate separateChildrenFromContracts={separateChildrenFromContracts}>
          {PageContent}
        </FactoringTemplate>
      );
    }
    case 'loans': {
      const [isInMaintenance, , maintenanceInfo] = isFeatureInMaintenance(['loans', 'navision']);

      if (isInMaintenance) {
        return <MaintenanceCard {...maintenanceInfo} />;
      }

      return (
        <LoansTemplate separateChildrenFromContracts={separateChildrenFromContracts}>
          {PageContent}
        </LoansTemplate>
      );
    }
    case 'leasing': {
      const [isInMaintenance, , maintenanceInfo] = isFeatureInMaintenance(['leasing', 'navision']);

      if (isInMaintenance) {
        return <MaintenanceCard {...maintenanceInfo} />;
      }

      return (
        <LeasingTemplate separateChildrenFromContracts={separateChildrenFromContracts}>
          {PageContent}
        </LeasingTemplate>
      );
    }
    case 'creditline': {
      const [isInMaintenance, , maintenanceInfo] = isFeatureInMaintenance(['creditline', 'navision']);

      if (isInMaintenance) {
        return <MaintenanceCard {...maintenanceInfo} />;
      }

      return (
        <CreditLineTemplate separateChildrenFromContracts={separateChildrenFromContracts}>
          {PageContent}
        </CreditLineTemplate>
      );
    }
    case 'rbf': {
      const [isInMaintenance, , maintenanceInfo] = isFeatureInMaintenance(['rbf', 'navision']);

      if (isInMaintenance) {
        return <MaintenanceCard {...maintenanceInfo} />;
      }

      return (
        <RbfTemplate separateChildrenFromContracts={separateChildrenFromContracts}>{PageContent}</RbfTemplate>
      );
    }
    default:
      return null;
  }
};

const renderDetailsPageContents = (category: string, subCategory: string, itemId: string) => {
  switch (`${category}/${subCategory}`) {
    case 'factoring/invoices':
      return <InvoiceDetails itemId={itemId} />;
    default:
      return null;
  }
};

const renderPageContents = (category: string, subCategory: string) => {
  switch (`${category}/${subCategory}`) {
    case 'factoring/overview':
      return <FactoringOverview />;
    case 'factoring/invoices':
      return <FactoringInvoices />;
    case 'factoring/bills':
      return <FactoringBills />;
    case 'factoring/statements':
      return <FactoringStatements />;
    case 'factoring/contracts':
      return <FactoringContracts />;
    case 'loans/overview':
      return <LoansOverview />;
    case 'loans/bills':
      return <LoansBills />;
    case 'loans/contracts':
      return <LoansContracts />;
    case 'leasing/overview':
      return <LeasingOverview />;
    case 'leasing/contracts':
      return <LeasingContracts />;
    case 'leasing/bills':
      return <LeasingBills />;
    case 'creditline/overview':
      return <CreditLineOverview />;
    case 'creditline/bills':
      return <CreditLineBills />;
    case 'creditline/contracts':
      return <CreditLineContracts />;
    case 'rbf/overview':
      return <RbfOverview />;
    case 'rbf/bills':
      return <RbfBills />;
    case 'rbf/contracts':
      return <RbfContracts />;
    default:
      return null;
  }
};

export const FinanceDashboard: FC = () => {
  const isGhostUser = useAuthSelector(selectIsUserGhost);

  const { category, subCategory, itemId } = useParams();

  const isFeatureInMaintenance = useMaintenanceMode();

  const defaultCategory = category || 'factoring';
  const defaultSubCategory = subCategory || 'overview';

  const PageContents = renderPageContents(defaultCategory, defaultSubCategory);

  if (!PageContents) {
    return <PageNotFound />;
  }

  if (isGhostUser) return null;

  return (
    <ScrollStateContextProvider>
      <Layout topBanner={<AdminBanner />} sideNavContent={<SideNavigation />} wide>
        {itemId
          ? renderDetailsPageContents(defaultCategory, defaultSubCategory, itemId)
          : renderPageTemplate(defaultCategory, defaultSubCategory, PageContents, isFeatureInMaintenance)}
      </Layout>
    </ScrollStateContextProvider>
  );
};

export default FinanceDashboard;
