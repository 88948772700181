import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setUserCRUDSuccessMessage, setUserCRUDErrorMessage } from 'features/app/slice';
import { selectActiveCustomer } from 'features/auth/selectors';
import {
  useLazyGetRelationsQuery,
  useLazyGetUserByEmailQuery,
  usePatchRelationMutation,
  useUpdateUserMutation
} from 'features/users/slice';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';

import { ROLES } from './useTriggerCreateUser';

const useTriggerUpdateUser = (email?: string, onSuccess?: () => unknown, onError?: () => unknown) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const [triggerGetRelationByEmailQuery] = useLazyGetRelationsQuery();
  const [triggerGetUserByEmailQuery] = useLazyGetUserByEmailQuery();
  const [patchRelation] = usePatchRelationMutation();
  const [updateUserData] = useUpdateUserMutation();

  const updateUser = async (phoneNumber?: string, selectedRole?: ROLES) => {
    if (!isSubmitting && email && selectedRole) {
      setIsSubmitting(true);
      const data = await triggerGetRelationByEmailQuery({ email: email ?? '' }).unwrap();

      const filteredUserRelation = data?.find(
        (customers) => customers.customerData?.customerCode === activeCustomer?.customerId
      );
      const filteredUserRelationId = filteredUserRelation?.relationId;

      if (filteredUserRelationId) {
        try {
          await patchRelation({
            id: `${filteredUserRelationId}`,
            content: { 'application/json': { assignedRoles: [selectedRole] } }
          }).unwrap();

          const user = await triggerGetUserByEmailQuery({ email }).unwrap();

          if (user.userId) {
            await updateUserData({
              id: `${user.userId}`,
              content: {
                'application/json': {
                  ...user,
                  mobilePhoneNumber: phoneNumber
                }
              }
            }).unwrap();
          } else {
            throw new Error('User not found');
          }

          dispatch(setUserCRUDSuccessMessage({ action: t('updated') }));
          onSuccess?.();
        } catch {
          dispatch(setUserCRUDErrorMessage({ action: t('updated') }));
          onError?.();
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  return { isSubmitting, updateUser };
};

export default useTriggerUpdateUser;
