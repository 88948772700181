import { FC, useState, ReactNode, useEffect, useId, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { getFlagCodeByValue } from '../countriesList/constants';
import { ErrorMessage } from '../formError';
import { FieldState } from '../formField/field';
import { InputContainer, InputMessagesContainer, PlaceholderLabel } from '../input/input.style';

import { getCustomStyles, SelectContainer, SelectElementWrapper } from './dropdown.style';
import { Label } from './Label';

export type Option = {
  value: string;
  label: string;
  sublabel?: string;
  icon?: ReactNode;
};

type Props = {
  value: string | undefined;
  options: Option[];
  placeholder: string;
  onChange: (value: string) => void;
  fieldMeta?: FieldState;
  disabled?: boolean;
  required?: boolean;
  sorted?: boolean;
  separateFromMenu?: boolean;
  onClick?: () => unknown;
  onVisibilityChange?: (visible: boolean) => unknown;
  displayFlag?: boolean;
  size?: 'M' | 'L';
};

export const Dropdown: FC<Props> = ({
  value,
  options,
  placeholder,
  onChange,
  fieldMeta,
  sorted,
  onVisibilityChange,
  required,
  disabled,
  separateFromMenu,
  displayFlag,
  size,
  onClick
}) => {
  const [sortedOptions, setSortedOptions] = useState(options);
  const [selectedValue, setSelectedValue] = useState<Option | undefined>(
    value ? options.find((option) => option.value === value) || undefined : undefined
  );

  const hasPlaceholder = Boolean(placeholder);

  const { t, i18n } = useTranslation();

  const inputId = useId();
  const id = `select-${inputId}`;
  const instanceId = `${id}-instance`;
  const controlId = `react-select-${instanceId}-input`;

  useEffect(() => {
    if (sorted) {
      const newlySortedOptions = sortedOptions
        .map(({ value, label, sublabel, icon }) => {
          return { value, label: t(label), sublabel, icon };
        })
        .sort((a: Option, b: Option) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();

          return nameA.localeCompare(nameB, i18n.language, { sensitivity: 'accent' });
        });

      setSortedOptions(newlySortedOptions);
    } else {
      setSortedOptions(options);
    }

    setSelectedValue(value ? options.find((option) => option.value === value) || undefined : undefined);
  }, [options, sorted, i18n.language, t]);

  const onSelect = (value: string) => {
    onChange(value);
    setSelectedValue(options.find((option) => option.value === value) || undefined);
  };

  const onToggle = (visible: boolean) => {
    onVisibilityChange?.(visible);
  };

  const getLabel = (element: Option): ReactNode => {
    const isSelectedElement = element.value === selectedValue?.value;
    const translatedElement = options.find((option) => option.value === value) || null;

    if (isSelectedElement) {
      return (
        <Label
          option={translatedElement}
          flagCode={
            displayFlag && translatedElement?.value ? getFlagCodeByValue(translatedElement?.value) : undefined
          }
        />
      );
    }

    return <Label option={element} />;
  };

  useEffect(() => {
    setSelectedValue(value ? options.find((option) => option.value === value) || undefined : undefined);
  }, [value, options]);

  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};

  const hasValidationMessages = error?.message && isTouched;

  const handleDropdownClick = (e: SyntheticEvent<HTMLDivElement>) => {
    if (separateFromMenu) {
      e.stopPropagation();
      e.preventDefault();

      onClick?.();
    }
  };

  const resolvedHeight = size ?? 'M';

  return (
    <InputContainer data-hj-suppress="true">
      <SelectContainer
        size={resolvedHeight}
        hasPlaceholder={hasPlaceholder}
        data-testid={placeholder}
        hasValue={Boolean(selectedValue)}
        invalidStatus={Boolean(hasValidationMessages)}
        disabled={disabled}
        onClick={handleDropdownClick}
        stopPropagationToChildren={separateFromMenu}
      >
        <SelectElementWrapper stopPropagationToChildren={separateFromMenu}>
          <Select<Option>
            noOptionsMessage={() => t('noResultsFound')}
            maxMenuHeight={256}
            menuPortalTarget={document.body}
            isDisabled={disabled}
            placeholder={''}
            value={selectedValue}
            id={id}
            instanceId={instanceId}
            onChange={(option: Option | null) => {
              onSelect(option?.value || '');
            }}
            options={options}
            formatOptionLabel={getLabel}
            onMenuOpen={() => onToggle(true)}
            onMenuClose={() => onToggle(false)}
            styles={getCustomStyles({ size: resolvedHeight })}
          />
          {placeholder && (
            <PlaceholderLabel htmlFor={controlId} hasValue={false} required={required}>
              {required ? placeholder : t('placeholderOptional', { placeholder })}
            </PlaceholderLabel>
          )}
        </SelectElementWrapper>
      </SelectContainer>
      <InputMessagesContainer>
        {hasValidationMessages && showValidationMessage ? (
          <ErrorMessage hidden={!hasValidationMessages || !showValidationMessage}>{message}</ErrorMessage>
        ) : null}
      </InputMessagesContainer>
    </InputContainer>
  );
};
