import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader, NotificationModal, PageHeaderRow, Paper, Section, SectionLayout } from 'components';
import { Button } from 'components/buttonV2';
import { ProductCard } from 'components/productCard';
import { Toast } from 'components/toastV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import {
  DashboardCardContainer,
  EmptyCapCardContainer
} from 'features/application/components/capCard/capCard.styles';
import { PERMISSIONS } from 'features/auth/consts/permissions';
import { selectActiveCustomer, selectCustomerInformation } from 'features/auth/selectors';
import { useFetchSelectedContract } from 'features/contracts/hooks';
import { resetContractsState } from 'features/contracts/slice';
import { FactoringContracts } from 'features/contracts/tables';
import { SubcategoriesNavigationBar } from 'features/nav/components';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';

import { ContentContainer } from './factoringTemplate.styles';

type Props = {
  children: ReactNode | ReactNode[];
  separateChildrenFromContracts?: boolean;
};

const getUploadInvoicesPageLink = (id?: string) => {
  return id ? `/finance/invoices/upload?contractId=${id}` : '/finance/invoices/upload';
};

export const FactoringTemplate: FC<Props> = ({ children, separateChildrenFromContracts }) => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showSkeleton, setShowSkeleton] = useState(true);

  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [openNoActiveContractsModal, setOpenNoActiveContractsModal] = useState(false);

  const memoizedPathname = useRef(pathname);

  const originalPathname = memoizedPathname.current;

  const { currentData, isLoading, isError, isUninitialized, refetch, selectedContract } =
    useFetchSelectedContract();

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const contracts = currentData?.contracts;
  const noContracts = !contracts?.length;
  const partyId = activeCustomer?.customerCrmId;

  const customerInformation = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);

  useEffect(() => {
    return () => {
      dispatch(resetContractsState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && isError) {
      if (pathname !== originalPathname) {
        refetch();
        memoizedPathname.current = pathname;
      }
    }
  }, [isLoading, isError, pathname, originalPathname, refetch]);

  useEffect(() => {
    setTimeout(() => {
      setShowSkeleton(false);
    }, 300);
  }, []);

  const haveInvoiceUploadRole =
    customerInformation?.roles && customerInformation.roles.indexOf(PERMISSIONS.uploadInvoices) > -1;

  const onClick = () => {
    const includeActiveContract = contracts?.some((contract) => contract.status === 'ACTIVE');

    const isActiveContract = selectedContract?.status === 'ACTIVE';

    haveInvoiceUploadRole
      ? includeActiveContract
        ? navigate(getUploadInvoicesPageLink(isActiveContract ? selectedContract.id : ''))
        : setOpenNoActiveContractsModal(true)
      : setOpenPermissionModal(true);
  };

  if (!isLoading && isUninitialized) {
    return partyId ? null : (
      <DashboardCardContainer>
        <EmptyCapCardContainer data-testid="empty-product-card">
          <ProductCard
            label={'getProductsExplanation'}
            link={{ to: '/dashboard/marketplace', label: 'getProducts' }}
          />
        </EmptyCapCardContainer>
      </DashboardCardContainer>
    );
  }

  if (isLoading) {
    return <Loader transparent />;
  }

  if (!isLoading && isError) {
    return <Toast isVisible header={t('generalErrorHeader')} message={t('generalErrorMessage')} />;
  }

  if (!isLoading && !isError) {
    if (noContracts) {
      return (
        <DashboardCardContainer>
          <EmptyCapCardContainer data-testid="empty-product-card">
            <ProductCard
              label={'getProductsExplanation'}
              link={{ to: '/dashboard/marketplace', label: 'getProducts' }}
            />
          </EmptyCapCardContainer>
        </DashboardCardContainer>
      );
    }
  }

  return (
    <>
      <NotificationModal
        type="warning"
        label="noPermissions"
        explanationText="noPermissionsDetails"
        isOpen={openPermissionModal}
        handleClose={setOpenPermissionModal}
      />
      <NotificationModal
        type="warning"
        label="noActiveContracts"
        explanationText="noActiveCotractsDetails"
        isOpen={openNoActiveContractsModal}
        handleClose={setOpenNoActiveContractsModal}
      />
      <SubcategoriesNavigationBar category="factoring" />
      <ContentContainer isLoading={showSkeleton}>
        <>
          <SectionLayout>
            <PageHeaderRow sticky>
              <span></span>
              {isFeatureEnabled('invoiceUpload') && !noContracts && !isLoading && haveInvoiceUploadRole ? (
                <Button data-testid="main-upload-invoices-button" onClick={onClick} size="S">
                  {t('uploadInvoices')}
                </Button>
              ) : null}
            </PageHeaderRow>
            <Paper>
              <Section header={t('contracts')}>
                <FactoringContracts loading={isLoading} items={contracts || []} />
              </Section>
              {!separateChildrenFromContracts ? children : null}
            </Paper>
          </SectionLayout>
          {separateChildrenFromContracts ? children : null}
        </>
      </ContentContainer>
    </>
  );
};
