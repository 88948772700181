import styled, { css } from 'styled-components';

export type ChipProps = {
  variant: 'radio' | 'checkbox';
  size: 'M' | 'L';
  rounded?: boolean;
  on?: 'light' | 'dark';
};

const hidden = css`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
`;

const checked = css`
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.primary.shades[100]};
  background-color: ${({ theme }) => theme.colors.base.primary.shades[5]};
`;

export const HiddenInput = styled.input`
  ${hidden}
`;

export const ChipOptionLabel = styled.label<ChipProps>`
  display: flex;
  position: relative;
  min-height: ${({ size }) => (size === 'M' ? '3.5rem' : '5rem')};
  padding: 1rem 1.5rem;
  align-items: center;
  justify-content: ${({ variant }) => (variant === 'radio' ? 'center' : 'flex-start')};
  border-radius: ${({ rounded }) => (rounded ? '6.25rem' : '1rem')};
  cursor: pointer;
  border: 0.0625rem solid
    ${({ theme, on }) =>
      on === 'dark' ? theme.colors.base.white.functional : theme.colors.base.black.shades[10]};
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  gap: 0.5rem;

  &:has(${HiddenInput}:checked) {
    ${checked};
  }

  &:not(:has(${HiddenInput}:checked)):hover {
    border-color: ${({ theme }) => theme.colors.primary.border.middle};
  }
`;
