import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { LinkButton, RegularText, SecondaryText } from 'components';
import { selectUserData } from 'features/auth/selectors';
import { useGetUserByEmailQuery } from 'features/users/slice';
import { useAuthSelector } from 'hooks/redux/hooks';

import { EditPhoneModal } from '../editPhoneModal/editPhoneModal';

import { Wrapper } from './phoneNumber.styles';

export const PhoneNumber = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const { email } = useAuthSelector<typeof selectUserData>(selectUserData);
  const { data } = useGetUserByEmailQuery({ email: email || '' }, { skip: !email });

  const onEditClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <EditPhoneModal isOpen={showModal} onVisibilityChange={setShowModal} userData={data} />
      <SecondaryText>{t('phoneNumber')}</SecondaryText>
      <Wrapper>
        <RegularText>{data?.mobilePhoneNumber || '-'}</RegularText>
        <LinkButton onClick={onEditClick}>
          <Icons.PencilOutline />
        </LinkButton>
      </Wrapper>
    </>
  );
};
