import { useTranslation } from 'react-i18next';

import { Drawer } from 'features/app/components/drawer';
import { useNavContext } from 'features/nav/context/navContext';

import { Roles, UserRole } from '../roles/roles';

export type RolesDrawerToggleParams = {
  selectedRole?: UserRole;
  inner?: boolean;
  onClose?: () => unknown;
};

const RolesDrawer = ({ inner, onClose, selectedRole }: RolesDrawerToggleParams) => {
  const { t } = useTranslation();

  const { hideSideDrawer } = useNavContext<RolesDrawerToggleParams>();

  const handleSave = async () => {
    inner ? onClose?.() : hideSideDrawer?.();
  };

  return (
    <Drawer
      inner={inner}
      isValid
      header={t('userRoles')}
      deleteButtonLabel={'deleteThisUser'}
      onSave={handleSave}
      onClose={handleSave}
      buttonLabel={t('gotIt')}
    >
      <Roles selectedRole={selectedRole} />
    </Drawer>
  );
};

export default RolesDrawer;
