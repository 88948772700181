import { useEffect, useRef } from 'react';

import AsideDrawer from './asideDrawer';
import { useSwipeTillConfirmAttributeMutationObserver } from './hooks';
import { Content, Main, MainContainer, MainContent, Nav, Overlay, PageColumn, TopNav } from './layout.styles';

type Props = {
  topNavContent?: React.ReactNode;
  bottomNavContent?: React.ReactNode;
  sideNavContent?: React.ReactNode;
  asideMenuContent?: React.ReactNode;
  topBanner?: React.ReactNode;
  children?: React.ReactNode;
  wide?: boolean;
  rightSideDrawerOpen?: boolean;
  onDrawerStateChange?: (isOpen: boolean) => unknown;
};

const useObserver = (cb: (isIntersecting: boolean) => unknown) => {
  const observer = useRef<IntersectionObserver | null>(null);

  observer.current = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      cb(entry.isIntersecting);
    });
  });

  return observer.current;
};

const Layout = ({
  sideNavContent,
  topNavContent,
  bottomNavContent,
  children,
  asideMenuContent,
  wide,
  topBanner,
  rightSideDrawerOpen,
  onDrawerStateChange
}: Props) => {
  const topMarkerRef = useRef<HTMLDivElement>(null);
  const bottomMarkerRef = useRef<HTMLDivElement>(null);
  const topNavRef = useRef<HTMLDivElement>(null);
  const bottomNavRef = useRef<HTMLDivElement>(null);
  const asideDrawerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const topMarkerObserver = useObserver((isIntersecting) => {
    if (topNavRef.current) {
      if (!isIntersecting) {
        topNavRef.current.classList.add('top-shadow');
      } else {
        topNavRef.current.classList.remove('top-shadow');
      }
    }
  });

  const bottomMarkerObserver = useObserver((isIntersecting) => {
    if (bottomNavRef.current) {
      if (!isIntersecting) {
        bottomNavRef.current.classList.add('bottom-shadow');
      } else {
        bottomNavRef.current.classList.remove('bottom-shadow');
      }
    }
  });

  useEffect(() => {
    if (topMarkerRef.current) {
      topMarkerObserver.observe(topMarkerRef.current);
    }

    return () => {
      if (topMarkerRef.current) {
        topMarkerObserver.unobserve(topMarkerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (bottomMarkerRef.current) {
      bottomMarkerObserver.observe(bottomMarkerRef.current);
    }

    return () => {
      if (bottomMarkerRef.current) {
        bottomMarkerObserver.unobserve(bottomMarkerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    onDrawerStateChange?.(!!rightSideDrawerOpen);

    document.body.classList.toggle('drawer-focused', !!rightSideDrawerOpen);

    return () => {
      document.body.classList.remove('drawer-focused');
    };
  }, [rightSideDrawerOpen]);

  const onSwipe = (tillConfirm: number) => {
    if (overlayRef.current) {
      if (tillConfirm) {
        overlayRef.current.style.backgroundColor = `rgba(0, 0, 0, ${Math.min(1 - tillConfirm, 0.5)})`;
      } else {
        overlayRef.current.removeAttribute('style');
      }
    }
  };

  useSwipeTillConfirmAttributeMutationObserver(asideDrawerRef.current, onSwipe);

  return (
    <PageColumn
      onClick={() => {
        if (rightSideDrawerOpen) {
          onDrawerStateChange?.(false);
        }
      }}
    >
      {topBanner}
      <Content>
        <MainContainer>
          <Nav>{sideNavContent}</Nav>
          <Main>
            <TopNav ref={topNavRef} notShown={!topNavContent}>
              {topNavContent}
            </TopNav>
            <div ref={topMarkerRef} id="top-marker"></div>
            <MainContent data-testid="main-content" id="main-content" wide={wide}>
              {children}
            </MainContent>
            <div ref={bottomMarkerRef} id="bottom-marker"></div>
            <TopNav position="bottom" ref={bottomNavRef} notShown={!bottomNavContent}>
              {bottomNavContent}
            </TopNav>
          </Main>
        </MainContainer>
        <Overlay ref={overlayRef} absolute={false} />
        <AsideDrawer ref={asideDrawerRef} absolute={false} isOpen={rightSideDrawerOpen}>
          {asideMenuContent}
        </AsideDrawer>
      </Content>
    </PageColumn>
  );
};

export default Layout;
