import { addYears, isBefore, parse } from 'date-fns';
import { getCountryCallingCode, type CountryCode } from 'libphonenumber-js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { DatePicker, Dropdown, Field, Fieldset, Input, PhoneField } from 'components';
import { getCountriesList } from 'components/countriesList/constants';
import { formatFullDateISO } from 'components/datePicker/utils';
import { CountriesDrawer } from 'features/app/components/countries';
import { Drawer } from 'features/app/components/drawer';
import { selectActiveCustomer, selectUserData } from 'features/auth/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { useLazyGetRelationsQuery, useLazyGetUserByEmailQuery } from 'features/users/slice';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAuthSelector } from 'hooks/redux/hooks';
import { UserData } from 'services/RbacService/typings';
import { Languages } from 'translations/translations';
import tracking from 'utils/tracking';
import { countryRequiredMessage, emailRequiredMessage, personNameRequiredMessage } from 'validators';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { UserDeleteConfirmationModal } from '../deleteConfirmationModal';
import { ROLES, UserRole, UserRolesGroup } from '../roles/roles';
import { StandaloneRolesDrawer } from '../rolesDrawer';

import useTriggerCreateUser from './hooks/useTriggerCreateUser';
import useTriggerDeleteUser from './hooks/useTriggerDeleteUser';
import useTriggerUpdateUser from './hooks/useTriggerUpdateUser';
import UserDrawerSkeleton from './skeleton';
import { ExplanationAboutInvitation } from './skeleton.styles';

const roleIds = {
  1: 'admin',
  2: 'viewer',
  3: 'editor',
  4: 'approver'
} as const;

export type CrudUserDrawerToggleParams = {
  customerId?: number;
  relationId?: number;
  email?: string;
  userEmails?: string[];
};

type FormFields = UserData & { userEmails: string[]; role: UserRole };

type ValidationSchema = Yup.ObjectSchema<Record<keyof FormFields, Yup.AnySchema>>;

const availableRoles: UserRole[] = ['admin', 'approver', 'editor', 'viewer'];

const userDefault: FormFields = {
  firstName: '',
  lastName: '',
  personalCode: '',
  birthDate: '',
  mobilePhoneNumber: undefined,
  userCountry: '',
  userEmail: '',
  userLanguage: '',
  userLevel: '200',
  role: '',
  userEmails: []
};

const validationSchema: ValidationSchema = Yup.object({
  firstName: personNameRequiredMessage('userNameIsRequired'),
  lastName: personNameRequiredMessage('userSurnameIsRequired'),
  personalCode: Yup.string()
    .test('', 'userPersonalCodeIsRequired', function () {
      const { personalCode, userCountry } = this.parent as FormFields;

      const personalCodeLength = personalCode?.length;
      const finlandPersonalCode = personalCode?.match(
        /^(?:0[1-9]|[12][\\d]|3[01])(?:0[1-9]|1[0-2])[\\d]{2}[-+A][\\d]{3}[0-9A-FHJ-NPR-Y]$/
      );

      if (userCountry === 'FI') {
        return personalCodeLength !== 11 && !finlandPersonalCode;
      }

      if (userCountry === 'NL') {
        return true;
      }

      return (personalCodeLength ?? 0) > 0;
    })
    // .min(1, VALIDATION_MESSAGE_ID.MIN_LENGTH)
    .max(15, VALIDATION_MESSAGE_ID.MAX_LENGTH),
  userCountry: countryRequiredMessage('userCountryIsRequired'),
  userEmail: emailRequiredMessage('userEmailIsRequired').test(
    'emailIsUniqueTest',
    'userEmailAlreadyExists',
    function (email?: string) {
      const { userEmails } = this.parent as FormFields;

      if (!email) {
        return false;
      }

      return !userEmails.includes(email);
    }
  ),
  birthDate: Yup.string()
    .test('', 'birthDateIsRequired', function (value) {
      const { userCountry } = this.parent as FormFields;

      if (userCountry === 'NL') {
        return Boolean(value);
      }

      return true;
    })
    .test('containsValidCharacters', 'dateValidFormat', (value) => {
      if (!value) return true;

      const containsValidCharacters = /^[0-9-]+$/.test(value);

      return containsValidCharacters;
    })
    .test('isNotBefore1900', 'dateIsNotBefore1900', (value) => {
      if (!value) return true;

      const date = parse(value, 'yyyy-MM-dd', new Date());
      const minDate = new Date(1900, 0, 1);

      return !isBefore(date, minDate);
    })
    .test('isOldEnough', 'dateIsYoungerThan18Years', (value) => {
      if (!value) return true;

      const date = parse(value, 'yyyy-MM-dd', new Date());
      const todayMinus18Years = addYears(new Date(), -18);

      return !isBefore(todayMinus18Years, date);
    }),
  mobilePhoneNumber: Yup.string().optional(),
  role: Yup.string().oneOf(availableRoles, 'userRoleIsRequired'),
  userLanguage: Yup.string().optional(),
  userLevel: Yup.string().optional(),
  blocked: Yup.boolean().optional(),
  userId: Yup.boolean().optional(),
  userEmails: Yup.array().optional(),
  agreementAccepted: Yup.boolean().optional(),
  initials: Yup.string().optional()
});

export const CrudUserDrawer = (): JSX.Element => {
  const { activeDrawer, hideSideDrawer } = useNavContext<CrudUserDrawerToggleParams>();

  const { email, customerId, userEmails } = activeDrawer ?? {};

  const { t, i18n } = useTranslation();

  const [user, setUser] = useState<FormFields>({ ...userDefault, userEmails: userEmails ?? [] });
  const [selectedRole, setSelectedRole] = useState(userDefault.role);
  const [isRolesDrawerOpen, setIsRolesDrawerOpen] = useState<{ selectedRole: UserRole; isOpen: boolean }>({
    selectedRole: '',
    isOpen: false
  });

  const fields = useMemo(() => ({ ...user, userEmails }), [user, userEmails]);

  const {
    validationHelpers,
    handleSubmit,
    setTouchedOnAll,
    getValues,
    getFieldState,
    setValueWithoutValidation,
    reset
  } = useFormHandlers<FormFields>(fields, validationSchema);

  const allCountriesOptions = getCountriesList(i18n.language as Languages);

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const userData = useAuthSelector<typeof selectUserData>(selectUserData);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [countriesListIsOpenFor, setCountriesListIsOpenFor] = useState<'' | 'country' | 'areaCode'>('');

  const [defaultAreaCodeCountry, setDefaultAreaCodeCountry] = useState<string>('LT');

  const [triggerGetUserByEmailQuery, { isFetching: isFetchingUser }] = useLazyGetUserByEmailQuery();
  const [triggerGetRelationByEmailQuery, { isFetching: isFetchingRelations }] = useLazyGetRelationsQuery();

  const updateInitialValues = async () => {
    if (email) {
      const userInfo = triggerGetUserByEmailQuery({ email }).unwrap();
      const relations = triggerGetRelationByEmailQuery({ email }).unwrap();

      const responses = Promise.allSettled([userInfo, relations]);

      const [userInfoResponse, relationsResponse] = await responses;

      if (userInfoResponse.status === 'fulfilled') {
        setUser({ ...user, ...userInfoResponse.value });
      }

      if (relationsResponse.status === 'fulfilled') {
        const filteredUserRelation = relationsResponse.value?.find(
          (customers) => customers.customerData?.customerCode === activeCustomer?.customerId
        );
        const currentRole = roleIds[filteredUserRelation?.roleData?.[0].roleId as keyof typeof roleIds];

        setSelectedRole(currentRole);
        setValueWithoutValidation('role', currentRole);
      }
    } else {
      setSelectedRole('');
      setValueWithoutValidation('role', '');
      setUser({ ...userDefault, userEmails: userEmails ?? [] });
    }
  };

  useEffect(() => {
    reset({ ...userDefault, userEmails: userEmails ?? [] });
    setDefaultAreaCodeCountry('LT');
    updateInitialValues();
  }, [email]);

  const onClose = () => {
    reset({ ...userDefault, userEmails: userEmails ?? [] });
    setDefaultAreaCodeCountry('LT');
    updateInitialValues();
    hideSideDrawer?.();
  };

  const { createUser, isSubmitting: isCreateSubmitting } = useTriggerCreateUser(customerId, onClose);
  const { updateUser, isSubmitting: isUpdateSubmitting } = useTriggerUpdateUser(email, onClose);
  const { deleteUser, isSubmitting: isDeleteSubmitting } = useTriggerDeleteUser(email, onClose);

  const onDeleteHandler = () => {
    setShowDeleteConfirmationModal(true);
  };

  const onUserAdd = async () => {
    handleSubmit(
      (payload) => {
        createUser(payload);
      },
      (error) => {
        console.error(error);
        setTouchedOnAll();
      }
    )();
  };

  const onRoleChanged = (value: string) => {
    setSelectedRole(value as UserRole);
    setValueWithoutValidation('role', value);
  };

  const countriesListDrawerIsOpen = ['areaCode', 'country'].includes(countriesListIsOpenFor);

  const handleCountrySelect = (isoCode: string) => {
    if (countriesListIsOpenFor === 'country') {
      setValueWithoutValidation('userCountry', isoCode);
    } else {
      setDefaultAreaCodeCountry(isoCode);
    }

    setCountriesListIsOpenFor('');
  };

  const getSelectedCountryValue = () => {
    if (countriesListIsOpenFor === 'areaCode') {
      return defaultAreaCodeCountry;
    }

    return getValues('userCountry');
  };

  const handleSave = async () => {
    if (email) {
      await updateUser(getValues('mobilePhoneNumber'), ROLES[selectedRole as keyof typeof ROLES]);
      tracking.setSubmitEvent('user_settings_edit');
    } else {
      await onUserAdd();
      tracking.setSubmitEvent('user_settings_created');
    }
  };

  const closeRolesDrawer = () => setIsRolesDrawerOpen({ selectedRole: '', isOpen: false });

  const isSubmitting = isCreateSubmitting || isUpdateSubmitting || isDeleteSubmitting;
  const loading = isFetchingUser || isFetchingRelations || isSubmitting;

  const rolesFieldsetMeta = getFieldState('role');
  const displayRolesValidationMessage = Boolean(
    rolesFieldsetMeta.isTouched && rolesFieldsetMeta.error?.message
  );
  const personalCodeInputType = getValues('userCountry') === 'FI' ? 'text' : 'number';

  const isInEdit = Boolean(email);

  const allowDelete = isInEdit && userData?.email !== email && !loading;
  const saveButtonLabel = isInEdit ? 'updateUser' : 'sendInvitation';
  const drawerHeaderLavel = isInEdit ? 'user' : 'inviteUser';
  const countriesDrawerHeader = countriesListIsOpenFor === 'country' ? 'country' : 'countryPhoneCode';

  const showWhitelistedCountries = countriesListIsOpenFor === 'country';

  const formatDate = (dateStr: string) => {
    if (/^\d{8}$/.test(dateStr)) {
      return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`;
    }

    if (/^\d{4}[/.-]\d{2}[/.-]\d{2}$/.test(dateStr)) {
      return dateStr.replace(/[/.,-]/g, '-');
    }

    return dateStr;
  };

  const handleBirthDateChange = (date: Date | null) => {
    const formattedDate = date ? formatDate(formatFullDateISO(date)) : '';

    setValueWithoutValidation('birthDate', formattedDate);
  };

  const isUserCountryNetherlands = getValues('userCountry') === 'NL';

  return (
    <>
      <StandaloneRolesDrawer
        selectedRole={isRolesDrawerOpen.selectedRole}
        isOpen={isRolesDrawerOpen.isOpen}
        onClose={closeRolesDrawer}
      />
      <CountriesDrawer
        portal={false}
        activeIndex={1}
        open={countriesListDrawerIsOpen}
        onClose={() => setCountriesListIsOpenFor('')}
        onSelect={handleCountrySelect}
        areaCodesEnabled={countriesListIsOpenFor === 'areaCode'}
        selectedValue={getSelectedCountryValue()}
        drawerHeader={t(countriesDrawerHeader)}
        whitelist={showWhitelistedCountries ? ['LT', 'LV', 'EE', 'FI', 'NL'] : undefined}
        searchable={countriesListIsOpenFor !== 'country'}
      />
      <UserDeleteConfirmationModal
        onClose={() => setShowDeleteConfirmationModal(false)}
        onDelete={deleteUser}
        isOpen={showDeleteConfirmationModal}
        userName={`${fields.firstName} ${fields.lastName}`}
        userRole={t(selectedRole, { context: 'caseWho' }).toLowerCase()}
      />
      <Drawer
        isValid
        isSubmitting={isSubmitting}
        header={t(drawerHeaderLavel)}
        deleteButtonLabel={'deleteThisUser'}
        onDelete={allowDelete ? onDeleteHandler : undefined}
        onSave={handleSave}
        disabledDelete={!allowDelete}
        onClose={onClose}
        buttonLabel={t(saveButtonLabel)}
      >
        {loading ? (
          <UserDrawerSkeleton />
        ) : (
          <>
            <ExplanationAboutInvitation>{t('userWillGetNotificationEmail')}</ExplanationAboutInvitation>
            <Fieldset>
              <Field
                name="userCountry"
                disabled={isInEdit}
                separateFromMenu
                onClick={() => (isInEdit ? undefined : setCountriesListIsOpenFor('country'))}
                displayFlag
                Component={Dropdown}
                validationHelpers={validationHelpers}
                options={allCountriesOptions}
                placeholder={t('country')}
                value={getValues('userCountry')}
                required
              />
              <Field
                name="firstName"
                disabled={isInEdit}
                Component={Input}
                validationHelpers={validationHelpers}
                placeholder={t('name')}
                value={getValues('firstName')}
                required
              />
              <Field
                name="lastName"
                disabled={isInEdit}
                Component={Input}
                validationHelpers={validationHelpers}
                placeholder={t('lastName')}
                value={getValues('lastName')}
                required
              />
              <Field
                name="userEmail"
                disabled={isInEdit}
                Component={Input}
                validationHelpers={validationHelpers}
                placeholder={t('email')}
                value={getValues('userEmail')}
                required
              />
              {!isUserCountryNetherlands ? (
                <Field
                  name="personalCode"
                  disabled={isInEdit}
                  type={personalCodeInputType}
                  maxDecimals={0}
                  Component={Input}
                  validationHelpers={validationHelpers}
                  placeholder={t('personalCode')}
                  value={getValues('personalCode')}
                  required
                />
              ) : (
                <Field
                  name="birthDate"
                  disabled={isInEdit}
                  Component={DatePicker}
                  validationHelpers={validationHelpers}
                  placeholder={t('birthDate')}
                  value={getValues('birthDate')}
                  onChange={handleBirthDateChange}
                  required
                  format="dd-MM-yyyy"
                />
              )}
              <Field
                name="mobilePhoneNumber"
                Component={PhoneField}
                defaultCountry={defaultAreaCodeCountry}
                onDropdownButtonClick={() => setCountriesListIsOpenFor('areaCode')}
                validationHelpers={validationHelpers}
                placeholder={t('phone')}
                required
                value={
                  getValues('mobilePhoneNumber') ??
                  getCountryCallingCode(defaultAreaCodeCountry as CountryCode)
                }
              />
            </Fieldset>
            <UserRolesGroup
              invalid={displayRolesValidationMessage}
              selectedRole={selectedRole}
              onChange={onRoleChanged}
              onInfoClick={(selectedRole) =>
                setIsRolesDrawerOpen({ selectedRole: selectedRole ?? '', isOpen: true })
              }
            />
          </>
        )}
      </Drawer>
    </>
  );
};
