import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import plus from 'assets/icons/plus.svg';
import { LinkButton, ListItem } from 'components';
import { ActionToast } from 'components/actionToast';
import { selectUserCrudError, selectUserCrudSuccess } from 'features/app/selectors';
import { clearUserCRUDMessage } from 'features/app/slice';
import { PERMISSIONS } from 'features/auth/consts/permissions';
import { selectActiveCustomer, selectUserData } from 'features/auth/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { userRole, UserRole, DrawerImplementation } from 'features/users/components';
import { useGetRelationsQuery, useGetUsersQuery } from 'features/users/slice';
import { useAppDispatch, useAppInfoSelector, useAuthSelector } from 'hooks/redux/hooks';
import { User } from 'services/RbacService/typings';

import Skeleton from './skeleton';
import { AddUserButton, Clause, List } from './users.style';

type UserInfo = User & { role?: UserRole; toggleSelected?: never };

export const Users = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { toggleRightSideDrawer, activeDrawer, hideSideDrawer } =
    useNavContext<DrawerImplementation<'editUser'>>();

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const successMessage = useAppInfoSelector<typeof selectUserCrudSuccess>(selectUserCrudSuccess);
  const errorMessage = useAppInfoSelector<typeof selectUserCrudError>(selectUserCrudError);

  const isAdmin = activeCustomer?.roles?.includes(PERMISSIONS.manageUsers);

  const { email } = useAuthSelector<typeof selectUserData>(selectUserData);

  const { data, isFetching: isRelationsFetching } = useGetRelationsQuery({ email: email || '' });

  const filteredCustomer = data?.find(
    (customers) => customers.customerData?.customerCode === activeCustomer?.customerId
  );

  const filteredCustomerId = filteredCustomer?.customerData?.customerId ?? 0;

  const { data: usersResponse, isFetching: isUsersFetching } = useGetUsersQuery(
    { id: filteredCustomerId },
    { skip: !filteredCustomerId }
  );
  const { users } = usersResponse || {};

  const sortedUsers = users
    ? [...users]?.sort((user) => {
        if (user.email === email) {
          return -1;
        }

        return 0;
      })
    : users;

  const userEmails = (users?.map((user) => user.email).filter((el) => el) as string[]) || [];

  const onAddUser = () => {
    toggleRightSideDrawer?.({
      drawerId: 'editUser',
      customerId: filteredCustomerId,
      userEmails
    });
  };

  const onUseEdit = (user?: UserInfo) => {
    if (activeDrawer?.drawerId === 'editUser' && activeDrawer.email === user?.email) {
      hideSideDrawer?.();
    } else {
      toggleRightSideDrawer?.({
        drawerId: 'editUser',
        customerId: filteredCustomerId,
        relationId: filteredCustomer?.relationId,
        email: user?.email,
        userEmails
      });
    }
  };

  const isLoading = isRelationsFetching || isUsersFetching;

  return (
    <>
      <ActionToast
        item={t('user')}
        action={successMessage?.action}
        isVisible={Boolean(successMessage)}
        variant={'success'}
        onClose={() => dispatch(clearUserCRUDMessage())}
      />
      <ActionToast
        item={t('user')}
        action={errorMessage?.action}
        isVisible={Boolean(errorMessage)}
        variant={'error'}
        onClose={() => dispatch(clearUserCRUDMessage())}
      />
      <List>
        {isLoading ? (
          <Skeleton />
        ) : (
          sortedUsers?.map((user) => (
            <LinkButton
              color="Black"
              key={user.email}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onUseEdit(user);
              }}
            >
              <ListItem
                content={`${user.firstName?.charAt(0) ?? ''}${user.lastName?.charAt(0) ?? ''}`}
                title={
                  <span>
                    {`${user.firstName} ${user.lastName}`}
                    {user.email === email ? <Clause>{` (${t('you')})`}</Clause> : ''}
                  </span>
                }
                subtitle={t(userRole(user.roles || []))}
              >
                <Icons.ChevronRight />
              </ListItem>
            </LinkButton>
          ))
        )}
      </List>
      {isAdmin ? (
        <AddUserButton
          icons={{ left: plus }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onAddUser();
          }}
        >
          {t('addUser')}
        </AddUserButton>
      ) : null}
    </>
  );
};
