import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AsideDrawer, CountriesList, CountryCodes, useDrawerCloseMutationObserver } from 'components';
import { useKeyboardHandler } from 'features/app/hooks';
import { Languages } from 'translations/translations';

import { InnerDrawer } from '../drawer';

type Props = {
  open: boolean;
  onClose: () => unknown;
  onSelect: (value: string) => unknown;
  selectedValue?: string;
  whitelist?: CountryCodes[];
  blacklist?: CountryCodes[];
  searchable?: boolean;
  drawerHeader?: string;
  activeIndex?: number;
  areaCodesEnabled?: boolean;
  portal?: boolean;
  rootDrawer?: boolean;
  prioritisedCountries?: CountryCodes[];
};

const phoneInputUnsupportedCountries: CountryCodes[] = [
  'AX',
  'AS',
  'GL',
  'VI',
  'KY',
  'MP',
  'MS',
  'AI',
  'VG',
  'TC',
  'BM',
  'JE',
  'FO',
  'FK',
  'GG',
  'GI',
  'CX',
  'KI',
  'CC',
  'CK',
  'YT',
  'IM',
  'NU',
  'NF',
  'BL',
  'MF',
  'PM',
  'SX',
  'SH',
  'SJ',
  'TK',
  'EH',
  'WF'
];

const CountriesDrawer = ({
  open,
  onClose,
  onSelect,
  selectedValue,
  whitelist,
  blacklist,
  drawerHeader,
  areaCodesEnabled,
  portal = true,
  rootDrawer = false,
  activeIndex = 0,
  searchable = true
}: Props) => {
  const { t, i18n } = useTranslation();

  const [searchQuery, setSearchQuery] = useState('');

  const drawerRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setTimeout(onClose, 150);
    setSearchQuery('');
  };

  useKeyboardHandler(handleClose, handleClose);
  useDrawerCloseMutationObserver(drawerRef.current, handleClose);

  const handleSelect = (value: CountryCodes) => {
    onSelect(value);
  };

  const isRoot = activeIndex === 0;

  const resolvedBlackList = [
    ...(blacklist ?? []),
    ...(areaCodesEnabled ? phoneInputUnsupportedCountries : [])
  ];

  const innerDrawer = (
    <InnerDrawer
      searchInputPlaceholder={t('search')}
      searchable={searchable}
      header={drawerHeader}
      onClose={handleClose}
      deepNested={!isRoot && !rootDrawer}
      onSearch={setSearchQuery}
      searchQuery={searchQuery}
    >
      <CountriesList
        areaCodesEnabled={areaCodesEnabled}
        searchQuery={searchQuery}
        whitelist={whitelist}
        selectedValue={selectedValue as CountryCodes}
        onSelect={handleSelect}
        onClick={handleClose}
        language={i18n.language as Languages}
        blacklist={resolvedBlackList}
      />
    </InnerDrawer>
  );

  if (isRoot) {
    return innerDrawer;
  }

  return (
    <AsideDrawer portal={portal} hideScroll ref={drawerRef} activeIndex={1} isOpen={open}>
      {innerDrawer}
    </AsideDrawer>
  );
};

export default CountriesDrawer;
