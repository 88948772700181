import { isFeatureEnabled } from 'defaultConfiguration/isFeatureEnabled';
import { CustomerApplication, Type } from 'services/CapService/types';

import { FINANCIAL_PRODUCT, STEPS } from '../types/applicationTypes';

type StepVerifierFunction = (response: CustomerApplication) => boolean;
type ApplicationModelKeys = keyof CustomerApplication;

type SequenceConfig = {
  [key in STEPS]?: { stepVerifier?: StepVerifierFunction; hidden?: boolean; type?: Type };
};

export type Flows = FINANCIAL_PRODUCT | 'createApplication';

type Sequences = {
  [key in Flows]: SequenceConfig;
};

/**
 * Validators for cap application response objects
 * return true means step CAN be considered completed
 * return false means step CANNOT be considered completed
 * -------------------------------------------------------
 */
const allwaysAllowed = () => true;
const doesCapIdExists: StepVerifierFunction = (response) => Boolean(response.capId);
const isConsentGiven: StepVerifierFunction = (response) => Boolean(response.applicationConsent?.dataHandling);

export const doesDataExists =
  (key: ApplicationModelKeys): StepVerifierFunction =>
  (response) => {
    if (Array.isArray(response[key])) {
      return (response[key] as Array<unknown>).length > 0;
    }

    if (typeof response[key] === 'object' && Boolean(response[key])) {
      return Object.keys(response[key] as Record<string, unknown>).length > 0;
    }

    return Boolean(response[key]);
  };
const lastStepInFlow = () => false;
/**
 * -------------------------------------------------------
 */

const LoansSequence: SequenceConfig = {
  [STEPS.SUBPRODUCT_TYPE]: { stepVerifier: allwaysAllowed, type: 'LOAN' },
  [STEPS.PURPOSE]: { stepVerifier: doesCapIdExists },
  [STEPS.AMOUNT]: { stepVerifier: doesCapIdExists },
  [STEPS.TERMS]: { stepVerifier: doesCapIdExists },
  [STEPS.CONTACT_PERSON]: { stepVerifier: isConsentGiven },
  [STEPS.BENEFICIAL_OWNERS]: { stepVerifier: doesDataExists('beneficialOwners') },
  [STEPS.BANK_ACCOUNTS]: { stepVerifier: doesCapIdExists },
  [STEPS.REVIEW]: { stepVerifier: lastStepInFlow }
};

const LeasingSequence: SequenceConfig = {
  [STEPS.SUBPRODUCT_TYPE]: { stepVerifier: allwaysAllowed },
  [STEPS.PRICE_AND_PAYMENT_DETAILS]: { stepVerifier: doesCapIdExists },
  [STEPS.CONTACT_PERSON]: { stepVerifier: isConsentGiven },
  [STEPS.BENEFICIAL_OWNERS]: { stepVerifier: doesDataExists('beneficialOwners') },
  [STEPS.REVIEW]: { stepVerifier: lastStepInFlow }
};

const RbfSequence: SequenceConfig = {
  [STEPS.AMOUNT]: { stepVerifier: doesCapIdExists },
  [STEPS.CONTACT_PERSON]: { stepVerifier: isConsentGiven },
  [STEPS.SUCCESS_SUBMIT]: { stepVerifier: isConsentGiven }
};

export const sequences: Sequences = {
  createApplication: {
    [STEPS.REGISTRATION_COUNTRY]: {},
    [STEPS.COMPANY]: {},
    [STEPS.PRODUCT_TYPE]: {}
  },
  [FINANCIAL_PRODUCT.FACTORING]: {
    [STEPS.AMOUNT]: { stepVerifier: doesCapIdExists },
    [STEPS.CONTACT_PERSON]: { stepVerifier: isConsentGiven },
    [STEPS.BENEFICIAL_OWNERS]: { stepVerifier: doesDataExists('beneficialOwners') },
    [STEPS.FACTORING_TYPE]: { stepVerifier: doesDataExists('factoringAdditionalInfo') },
    [STEPS.THIRD_PARTIES]: { stepVerifier: doesDataExists('thirdParties') },
    [STEPS.BANK_ACCOUNTS]: { stepVerifier: doesCapIdExists },
    [STEPS.REVIEW]: { stepVerifier: lastStepInFlow }
  },
  [FINANCIAL_PRODUCT.BUSINESS_LOANS]: LoansSequence,
  [FINANCIAL_PRODUCT.RBF]: RbfSequence,
  [FINANCIAL_PRODUCT.LEASING]: LeasingSequence
};

/**
 * Verifies each steps stepVerifier function agains cap aaplication response
 * return latest step where validator succeeded;
 * @param response - cap application data
 * @param sequence - product sequence of flow steps with verifiers
 * @returns string
 */
export const getLatestStep = (response: CustomerApplication, sequence: SequenceConfig) => {
  let latestStep = STEPS.PRODUCT_TYPE;
  const keys = Object.keys(sequence);

  for (const element of keys) {
    const config = sequence[element as STEPS];

    if (config) {
      const isValid = config.stepVerifier?.(response);

      if (!isValid) {
        latestStep = element as STEPS;
        break;
      }
    }
  }

  return latestStep;
};

/**
 * Verifies if step provided doesn't jump over unfilled product sequence steps
 * return passed in step if true;
 * return latest step where validator succeeded if false;
 * @param step - step to be verified, if cap flow can navigate to
 * @param response - cap application data
 * @param sequence - product sequence of flow steps with verifiers
 * @returns string
 */
export const validateStepAgainsResponse = (
  step: string | null,
  response: CustomerApplication,
  sequence: SequenceConfig
) => {
  const latestStepWithData = getLatestStep(response, sequence);

  if (!step) {
    return latestStepWithData;
  }

  const latestStepWithDataIndex = Object.keys(sequence).findIndex(
    (sequenceStep) => sequenceStep === latestStepWithData
  );
  const stepIndex = Object.keys(sequence).findIndex((sequenceStep) => sequenceStep === step);

  if (latestStepWithDataIndex < stepIndex) {
    return latestStepWithData;
  }

  return step;
};

const checkSubproductStepAvailable = (step: STEPS) => {
  const subproductsStepEnabled = isFeatureEnabled('subproductsInApplications');

  if (step === STEPS.SUBPRODUCT_TYPE) {
    return subproductsStepEnabled;
  }

  return true;
};

const checkIfNordigenStepAvailable = (step: STEPS) => {
  const nordigenStepEnabled = isFeatureEnabled('nordigenStatementsInApplications');

  if (step === STEPS.BANK_ACCOUNTS) {
    return nordigenStepEnabled;
  }

  return true;
};

const checkIfLeasingCAPStepsAvailable = (step: STEPS, type?: Type) => {
  const leasingCAPStepsEnabled = isFeatureEnabled('leasingInCap');

  if (type === 'LEASING') {
    if (step === STEPS.SUCCESS_SUBMIT) {
      return !leasingCAPStepsEnabled;
    }

    if ([STEPS.REVIEW, STEPS.BENEFICIAL_OWNERS].includes(step)) {
      return leasingCAPStepsEnabled;
    }
  }

  return true;
};

const getStepsArray = (sequence: SequenceConfig, type?: Type) => {
  const steps = Object.keys(sequence) as STEPS[];

  const filteredFeatures = steps.filter(
    (step) => checkIfNordigenStepAvailable(step) && checkSubproductStepAvailable(step)
  );

  const filteredProductSteps = filteredFeatures.filter((step) => checkIfLeasingCAPStepsAvailable(step, type));

  return filteredProductSteps.filter((step) => {
    const config = sequence[step];

    if (config?.type) {
      return config.type === type;
    }

    return true;
  });
};

export const getStepsArrayByFlow = (
  flow: Flows,
  sequences: Sequences,
  type?: Type,
  existingCustomer?: boolean
) => {
  const applicationInitSeps = existingCustomer
    ? [STEPS.PRODUCT_TYPE]
    : getStepsArray(sequences.createApplication);

  if (flow === 'createApplication') {
    const placeholderStepsArray = Array.from({ length: 5 }, (_, i) => `placeholder${i + 1}` as STEPS);

    return [...applicationInitSeps, ...placeholderStepsArray] as unknown as STEPS[];
  }

  return [...applicationInitSeps, ...getStepsArray(sequences[flow], type)] as unknown as STEPS[];
};

export const getStepsByProductType = (type?: Type, existingCustomer?: boolean) => {
  const typeToFlowMap: Record<Type, FINANCIAL_PRODUCT> = {
    FACTORING: FINANCIAL_PRODUCT.FACTORING,
    LOAN: FINANCIAL_PRODUCT.BUSINESS_LOANS,
    CREDIT_LINE: FINANCIAL_PRODUCT.BUSINESS_LOANS,
    LEASING: FINANCIAL_PRODUCT.LEASING,
    RBF: FINANCIAL_PRODUCT.RBF
  };

  if (type) {
    return getStepsArrayByFlow(typeToFlowMap[type], sequences, type, existingCustomer);
  }

  return getStepsArrayByFlow('createApplication', sequences, type, existingCustomer);
};
