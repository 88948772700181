import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setUserCRUDErrorMessage, setUserCRUDSuccessMessage } from 'features/app/slice';
import { selectActiveCustomer } from 'features/auth/selectors';
import { useDeleteRelationMutation, useLazyGetRelationsQuery } from 'features/users/slice';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';

const useTriggerDeleteUser = (email?: string, onSuccess?: () => unknown, onError?: () => unknown) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const [deleteRelation] = useDeleteRelationMutation();
  const [triggerGetRelationByEmailQuery] = useLazyGetRelationsQuery();

  const deleteUser = async () => {
    if (!isSubmitting) {
      const data = await triggerGetRelationByEmailQuery({ email: email ?? '' }).unwrap();

      const filteredUserRelation = data?.find(
        (customers) => customers.customerData?.customerCode === activeCustomer?.customerId
      );
      const filteredUserRelationId = filteredUserRelation?.relationId;

      setIsSubmitting(true);

      try {
        if (filteredUserRelationId) {
          await deleteRelation({ id: filteredUserRelationId }).unwrap();
        } else {
          throw new Error('User relation not found');
        }
        dispatch(setUserCRUDSuccessMessage({ action: t('deleted') }));
        onSuccess?.();
      } catch {
        dispatch(setUserCRUDErrorMessage({ action: t('deleted') }));
        onError?.();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return { isSubmitting, deleteUser };
};

export default useTriggerDeleteUser;
