import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Logo, InlineLink, Modal, Checkbox, Loader } from 'components';
import { selectCustomerInformation } from 'features/auth/selectors';
import { logoutAsync, setAgreementAccepted } from 'features/auth/slice';
import { useLazyGetUserByEmailQuery, usePatchUserMutation } from 'features/users/slice';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';

import {
  BoldedText,
  HeaderText,
  TextBox,
  TitleText,
  OrderedList,
  OrderedListItem
} from './generalTermsAndConditions.styles';

type Mode = 'view' | 'edit';

type ViewProps = {
  isOpen: boolean;
  onClose: () => void;
} & Partial<EditProps>;

type EditProps = {
  onAgree: () => void;
  isMainActionDisabled: boolean;
  mainActionLabel: string;
  footer: React.ReactNode;
  isLoading: boolean;
};

type Props<TMode extends Mode> = TMode extends 'edit' ? ViewProps & EditProps : ViewProps;

export function GeneralTermsAndConditionsRepresentation<TMode extends Mode = 'edit'>({
  isOpen,
  onClose,
  onAgree,
  isMainActionDisabled,
  mainActionLabel,
  isLoading,
  footer
}: Props<TMode>) {
  const { t } = useTranslation();

  return (
    <Modal
      termsModal
      isOpen={isOpen}
      onClose={onClose}
      mainActionhandler={onAgree}
      mainActionDisabled={isMainActionDisabled}
      mainActionLabel={mainActionLabel ? t(mainActionLabel) : mainActionLabel}
      footer={footer}
    >
      {isLoading ? <Loader /> : null}
      <Logo />
      <TextBox>
        <Trans
          i18nKey="termsAndConditionsContent"
          components={{
            header: <HeaderText />,
            t: <TitleText />,
            b: <BoldedText />,
            ol: <OrderedList />,
            li: <OrderedListItem />,
            mail: <InlineLink href="mailTo:pagalba@smefinance.lt" />,
            smeLink: <InlineLink href="https://www.smefinance.lt" target="_blank" rel="noopener noreferrer" />
          }}
        />
      </TextBox>
    </Modal>
  );
}

export const GeneralTermsAndConditions = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const customerInfo = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const { userAgreementAccepted, userId: email } = customerInfo || {};

  const [triggerUserPatch, { isLoading }] = usePatchUserMutation();
  const [triggerGetUserByEmailQuery, { isFetching }] = useLazyGetUserByEmailQuery();

  const [agreed, setAgreed] = useState(false);
  const [isOpen, setIsOpen] = useState(!userAgreementAccepted);

  const handleClose = () => {
    dispatch(logoutAsync());
    setIsOpen(false);
  };

  const handleAgree = async () => {
    try {
      if (email) {
        const response = await triggerGetUserByEmailQuery({
          email: email.replace('+', encodeURIComponent('+'))
        }).unwrap();

        if (response) {
          if (response.userId) {
            const userIdString = `${response.userId}`;

            if (userIdString) {
              await triggerUserPatch({
                agreementAccepted: true
              }).unwrap();
              dispatch(setAgreementAccepted(true));
            } else {
              throw new Error('userId is missing');
            }
          } else {
            throw new Error('user data is missing');
          }
        } else {
          throw new Error('relation fetch failed');
        }

        setIsOpen(false);
      }
    } catch {
      handleClose();
    }
  };

  return (
    <GeneralTermsAndConditionsRepresentation<'edit'>
      isLoading={isFetching || isLoading}
      footer={
        <Checkbox label={t('agreementPartnershipTextForContact')} onChange={setAgreed} checked={agreed} />
      }
      isOpen={isOpen}
      onClose={handleClose}
      onAgree={handleAgree}
      isMainActionDisabled={!agreed}
      mainActionLabel={'agree'}
    />
  );
};
