import { useEffect } from 'react';

import { selectActiveCompany } from 'features/applications/selectors';
import { useModalContext } from 'features/nav/context/modalContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux/hooks';
import { AppService } from 'services';
import { ExistingCustomerOnboardingStatusResponse } from 'services/OnboardingService/types';

import { updateQueryData, useLazyFetchExistingCustomerOnboardingStatusQuery } from '../api';
import {
  selectCompanyQuestionaireStatus,
  selectIsCompanyOnboardingNeeded,
  selectIsCompanyQuestionaireStatusLoading
} from '../selectors';

const shouldTriggerQuestionaireCreation = () => {
  return new URLSearchParams(location.search).get('createQuestionaire') === 'true';
};

import useExistingCustomerOnboardingInitiationHandler from './useExistingCustomerOnboardingInitiationHandler';
import useIsExistingCustomerOnboardingEnabled from './useIsExistingCustomerOnboardingEnabled';

type LazyTrigger = ReturnType<typeof useLazyFetchExistingCustomerOnboardingStatusQuery>['0'];

const statusesToShowUpdateProfile = ['NOT_FOUND', 'IN_PROGRESS', 'REJECTED'];

const validateOndatoWithRetry = async (
  companyId: string,
  trigger: LazyTrigger,
  breakerCount: number = 12
): Promise<ExistingCustomerOnboardingStatusResponse> => {
  if (breakerCount === 0) {
    const noSuccessResponseError = new Error(
      `Failed to validate existing customer ${companyId} ondato success; Retry count exceeded: ${breakerCount}`
    );

    AppService.reportError({ message: noSuccessResponseError.message });

    return Promise.reject(noSuccessResponseError);
  }

  await new Promise((resolve) => setTimeout(resolve, 1500)); // sleep in between calls 1.5s sleep -> 1req -> 3s sleep -> 1req ...
  const response = await trigger({ cacheKey: companyId }).unwrap();

  if (response.ondatoStatus !== 'FINISHED') {
    await new Promise((resolve) => setTimeout(resolve, 1500));

    return await validateOndatoWithRetry(companyId, trigger, breakerCount - 1);
  }

  return response;
};

const useFetchExistingCustomerOnboardingStatus = () => {
  const dispatch = useAppDispatch();

  const { modals } = useModalContext();

  const companyQuestionaireStatus = useAppSelector(selectCompanyQuestionaireStatus);
  const isFetching = useAppSelector(selectIsCompanyQuestionaireStatusLoading);
  const isOnboardingNeeded = useAppSelector(selectIsCompanyOnboardingNeeded);

  const activeCompany = useAppSelector(selectActiveCompany);

  const isFeatureEnabled = useIsExistingCustomerOnboardingEnabled();

  const onboardingInitiationHandler =
    useExistingCustomerOnboardingInitiationHandler(companyQuestionaireStatus);

  const [checkExistingCustomerOnboardingStatus] = useLazyFetchExistingCustomerOnboardingStatusQuery();

  const onboardingNeeded =
    isFeatureEnabled &&
    isOnboardingNeeded &&
    statusesToShowUpdateProfile.includes(companyQuestionaireStatus?.onboardingStatus ?? '');

  useEffect(() => {
    (async () => {
      try {
        if (shouldTriggerQuestionaireCreation() && isOnboardingNeeded) {
          modals?.get('flow-lobby')?.({ screen: 'ondato', visible: true });

          const response = await validateOndatoWithRetry(
            // refetch on a seperate key to avoid card reloads as same call is used for both (revalidation and initial status fetch)
            `${activeCompany.id}_revalidate`,
            checkExistingCustomerOnboardingStatus
          );

          if (response.ondatoStatus === 'FINISHED') {
            // redirect to onboarding initiation handler after success
            onboardingInitiationHandler();

            // sync up the status with the original key (if revalidation return successful result - ondato finished)
            dispatch(
              updateQueryData(
                'fetchExistingCustomerOnboardingStatus',
                { cacheKey: activeCompany?.id },
                (draft) => {
                  draft.ondatoUrl = response?.ondatoUrl;
                  draft.ondatoStatus = response?.ondatoStatus;
                  draft.onboardingStatus = response?.onboardingStatus;
                }
              )
            );
          }
        }
      } finally {
        modals?.get('flow-lobby')?.({ screen: 'ondato', visible: false });
      }
    })();
  }, [isOnboardingNeeded, shouldTriggerQuestionaireCreation()]);

  return {
    data: companyQuestionaireStatus,
    isFetching,
    isFeatureEnabled,
    onboardingNeeded,
    onboardingInitiationHandler
  };
};

export default useFetchExistingCustomerOnboardingStatus;
