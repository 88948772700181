import { useNavContext } from 'features/nav/context/navContext';

import { CrudUserDrawer, CrudUserDrawerToggleParams } from '../crudUserDrawer';
import { RolesDrawer, RolesDrawerToggleParams } from '../rolesDrawer';

type GenericDrawer = {
  drawerId: 'editUser' | 'roles';
};

type Implementations = {
  editUser: CrudUserDrawerToggleParams;
  roles: RolesDrawerToggleParams;
};

export type DrawerImplementation<TId extends GenericDrawer['drawerId']> = Implementations[TId] & {
  drawerId: TId;
};

const DrawersSwitch = () => {
  const { activeDrawer } = useNavContext<GenericDrawer>();

  if (!activeDrawer) return null;

  switch (activeDrawer.drawerId) {
    case 'editUser':
      return <CrudUserDrawer />;
    case 'roles':
      return <RolesDrawer />;

    default:
      return null;
  }
};

export default DrawersSwitch;
