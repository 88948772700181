import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import doc from 'assets/icons/page.svg';
import { FileChip, LinkButton, StatusChip } from 'components';
import { Button } from 'components/buttonV2';
import { AsideMenuTopBar } from 'components/pageV2';
import { OverlayView } from 'components/pageV2/overlayView';
import { formatNumber, formatPercentage } from 'components/text/formatters';
import Typography from 'components/typography/typography';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { getOnboardingSSO } from 'defaultConfiguration/getOnboardingSSO';
import {
  useAcceptApplicationOfferMutation,
  useFetchOfferFilenamesQuery,
  useRejectApplicationOfferMutation,
  useUpdateApplicationOfferStatusMutation
} from 'features/application/api';
import { useLazyFetchApplicationDetails } from 'features/application/hooks';
import {
  selectApplicationData,
  selectLoansAdditionalInfo,
  selectProductContractInfo,
  selectShoudOpenBankAccount
} from 'features/application/selectors';
import {
  selectIsActiveCompanyVerified,
  selectVerificationApplication
} from 'features/applications/selectors';
import { useModalContext } from 'features/nav/context/modalContext';
import { useLazyCheckForApplicationStatusQuery } from 'features/onboarding/api';
import { GradientBankLogo } from 'features/onboarding/components/bankCard/bankCard.styles';
import { useGetOnboardingEnabledConfig } from 'features/onboarding/hooks';
import { useAppSelector, useApplicationSelector } from 'hooks/redux/hooks';
import { ApplicationService } from 'services';
import { camelCase } from 'utils';
import tracking from 'utils/tracking';

import OfferDocumentSkeleton from './offerDocumentSkeleton';
import OfferStatusConfirmationModal from './offerStatusConfirmationModal';
import {
  FileChipContainer,
  LabelContainer,
  OfferDrawerHeader,
  OfferFileCard,
  OfferFileCardButton,
  OfferFileCardFileName,
  OfferStatusActions,
  OfferStatusContent,
  OfferStatusContentInfo,
  OfferStatusContentInfoHeader,
  OfferStatusInfoRow,
  OfferStatusInnerContent,
  OfferTermsCard,
  OfferTermsCardRow,
  OfferTermsCardRowHeader,
  OfferTermsCardRowValue,
  Title
} from './offerStatusModal.styles';
import OfferStatusPushModal from './offerStatusPushModal';

export type ToggleParams = { applicationId: string };

type Props = {
  readonly?: boolean;
  application?: { applicationId?: string; offerId?: string };
  toggleDrawer?: () => unknown;
  openBankAccountDrawer?: (inner: boolean, visible: boolean) => undefined | void;
};

const getFileNameTranslation = (fileName: string) => {
  if (fileName.toLowerCase().includes('offer')) {
    return 'offerDetails';
  }

  if (fileName.toLowerCase().includes('schedule')) {
    return 'repaymentSchedule';
  }

  return fileName;
};

export const OfferStatusModal = ({ application, readonly, toggleDrawer, openBankAccountDrawer }: Props) => {
  const { isFeatureEnabled } = useRemoteConfigContext();
  const { registerModalHash } = useModalContext<ToggleParams>();
  const { t, i18n } = useTranslation();

  const isOnboardingSSOEnabled = isFeatureEnabled('onboardingSSO');

  const { isParallelFlowEnabled } = useGetOnboardingEnabledConfig();

  const [acceptApplicationOffer, { isLoading: isAcceptLoading }] = useAcceptApplicationOfferMutation();
  const [rejectApplicationOffer, { isLoading: isRejectLoading }] = useRejectApplicationOfferMutation();
  const [updateOfferStatus, { isLoading: isUpdateLoading }] = useUpdateApplicationOfferStatusMutation();

  const [applicationId, setApplicationId] = useState(application?.applicationId ?? '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmationStatus, setShowConfirmationStatus] = useState(false);
  const [showPushStatus, setShowPushStatus] = useState(false);
  const [decisionStatus, setDecisionStatus] = useState<'error' | 'success'>('success');

  const isVerified = useAppSelector(selectIsActiveCompanyVerified);
  const activeApplication = useAppSelector(selectVerificationApplication);
  const applicationDetails = useApplicationSelector<typeof selectApplicationData>(selectApplicationData);
  const loansAdditionalInfo =
    useApplicationSelector<typeof selectLoansAdditionalInfo>(selectLoansAdditionalInfo);
  const shouldOpenBankAccount =
    useApplicationSelector<typeof selectShoudOpenBankAccount>(selectShoudOpenBankAccount);

  const paymentDetails = useApplicationSelector<typeof selectProductContractInfo>(selectProductContractInfo)(
    i18n.language,
    t
  );

  const onboardingSSOCountriesList = getOnboardingSSO();
  const enableOndato = onboardingSSOCountriesList.includes(
    applicationDetails.generalInfo?.company?.country ?? ''
  );

  const [checkForOndatoStatus, { isFetching: ondatoStatusIsFetching, isUninitialized }] =
    useLazyCheckForApplicationStatusQuery();

  const { data: offer, isFetching } = useFetchOfferFilenamesQuery(
    { applicationId },
    { skip: !applicationId }
  );

  const { filenames, offerId } = offer ?? {};

  const [fetchApplicationByIdAsync] = useLazyFetchApplicationDetails();

  useEffect(() => {
    if (application?.applicationId || applicationId) {
      fetchApplicationByIdAsync({
        capId: '',
        applicationId: application?.applicationId ?? applicationId
      });
    }
  }, [application?.applicationId, applicationId]);

  const [isOpen, setIsOpen] = useState(readonly ?? false);

  const shortenedFileNames = filenames?.map((fileName) => fileName.replace(/(.*\/)*/, ''));

  const handleClose = () => {
    setIsOpen(false);
    setIsSubmitting(false);
    setShowPushStatus(false);
  };

  const handleDownload = async (fileName: string) => {
    try {
      if (fileName && offerId) {
        return await ApplicationService.getOfferById(
          application?.applicationId ?? applicationId,
          offerId,
          fileName
        );
      }
    } catch {
      //fail silently
    }
  };

  const handleSubmit = async () => {
    const reject = decisionStatus === 'error';

    try {
      if (!isAcceptLoading && !isRejectLoading && !isOnboardingSSOEnabled && offerId) {
        if (reject) {
          await rejectApplicationOffer({
            applicationId: application?.applicationId ?? applicationId,
            offerId,
            comment: ''
          }).unwrap();
        } else {
          await acceptApplicationOffer({
            applicationId: application?.applicationId ?? applicationId,
            offerId,
            comment: ''
          }).unwrap();
        }
      }

      if (isOnboardingSSOEnabled && !isUpdateLoading && offerId) {
        const accepted = !reject;

        await updateOfferStatus({ offerId, applicationId, accepted, comment: '' }).unwrap();

        if (accepted) {
          await checkForOndatoStatus({ applicationId }).unwrap();
        }
      }

      setDecisionStatus(reject ? 'error' : 'success');

      tracking.setSubmitEvent(reject ? 'single_flow_offer_rejected' : 'single_flow_offer_accepted');

      if (isOnboardingSSOEnabled && enableOndato) {
        setShowConfirmationStatus(!isVerified);
      }

      handleClose();
    } catch {
      //fail silently
    }
  };

  const onSubmit = (decistionStatus: 'error' | 'success') => () => {
    setDecisionStatus(decistionStatus);
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (isSubmitting) {
      handleSubmit();
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (!readonly) {
      const registry = registerModalHash('offer-status', ({ applicationId } = { applicationId: '' }) => {
        setApplicationId(applicationId || '');
        setIsOpen(true);
      });

      return () => {
        registry.unregister();
      };
    }
  }, [registerModalHash, readonly]);

  const amount = formatNumber(i18n.language);
  const percentage = formatPercentage(i18n.language);

  const handleDrawerClose = () => {
    toggleDrawer?.();
  };

  const handleBankAccountDrawer = () => {
    openBankAccountDrawer?.(true, true);
  };

  const contents = (
    <OfferStatusInnerContent data-testid="modal-offer-status">
      {readonly ? null : <Typography.Header type="h2">{t('whatWeOffer')}</Typography.Header>}
      <OfferStatusContentInfo>
        <OfferStatusContentInfoHeader>
          <OfferStatusInfoRow>
            <Typography.Header weight="medium" type="h1">
              {amount(activeApplication?.requestedAmount)}
            </Typography.Header>
            {readonly ? <StatusChip color="green">{t('offerAccepted')}</StatusChip> : null}
          </OfferStatusInfoRow>
          <Typography.Body>{t(camelCase(activeApplication?.type ?? ''))}</Typography.Body>
        </OfferStatusContentInfoHeader>
        {paymentDetails ? (
          <OfferTermsCard variant={readonly ? 'dark' : 'light'}>
            {paymentDetails.term ? (
              <OfferTermsCardRow>
                <OfferTermsCardRowHeader>{t('repaymentTerm')}</OfferTermsCardRowHeader>
                <OfferTermsCardRowValue>{t('months', { value: paymentDetails.term })}</OfferTermsCardRowValue>
              </OfferTermsCardRow>
            ) : null}
            {paymentDetails.advancePayment ? (
              <OfferTermsCardRow>
                <OfferTermsCardRowHeader>{t('advancePayment')}</OfferTermsCardRowHeader>
                <OfferTermsCardRowValue>{paymentDetails.advancePaymentFormated}</OfferTermsCardRowValue>
              </OfferTermsCardRow>
            ) : null}
            {paymentDetails.residualAmount ? (
              <OfferTermsCardRow>
                <OfferTermsCardRowHeader>{t('residualAmount')}</OfferTermsCardRowHeader>
                <OfferTermsCardRowValue>{paymentDetails.residualAmountFormated}</OfferTermsCardRowValue>
              </OfferTermsCardRow>
            ) : null}
            {paymentDetails.propertyPrice ? (
              <OfferTermsCardRow>
                <OfferTermsCardRowHeader>{t('propertyPrice')}</OfferTermsCardRowHeader>
                <OfferTermsCardRowValue>{paymentDetails.propertyPriceFormated}</OfferTermsCardRowValue>
              </OfferTermsCardRow>
            ) : null}
            {paymentDetails.anualInterestRate ? (
              <OfferTermsCardRow>
                <OfferTermsCardRowHeader>{t('anualInterestRate')}</OfferTermsCardRowHeader>
                <OfferTermsCardRowValue>{paymentDetails.anualInterestRate}</OfferTermsCardRowValue>
              </OfferTermsCardRow>
            ) : null}
            {paymentDetails.administrationFee ? (
              <OfferTermsCardRow>
                <OfferTermsCardRowHeader>{t('adminisitrationFee')}</OfferTermsCardRowHeader>
                <OfferTermsCardRowValue>{amount(paymentDetails.administrationFee)}</OfferTermsCardRowValue>
              </OfferTermsCardRow>
            ) : null}
          </OfferTermsCard>
        ) : null}
        {isFeatureEnabled('openBankAccountWithOffer') && shouldOpenBankAccount ? (
          <div>
            <LabelContainer>
              <Typography.Body weight="medium">{t('specialConditionsToNote')}</Typography.Body>
            </LabelContainer>
            <OfferTermsCard variant={readonly ? 'dark' : 'light'}>
              <OfferTermsCardRow>
                <OfferTermsCardRowHeader>{t('openBankAccountWith')}</OfferTermsCardRowHeader>
                <OfferTermsCardRowValue>
                  <GradientBankLogo />
                </OfferTermsCardRowValue>
              </OfferTermsCardRow>
              <OfferTermsCardRow>
                <OfferTermsCardRowHeader>{t('minimumTurnover')}</OfferTermsCardRowHeader>
                <OfferTermsCardRowValue>
                  {percentage(loansAdditionalInfo?.commercialTurnover, 0)}
                </OfferTermsCardRowValue>
              </OfferTermsCardRow>

              <OfferTermsCardRow>
                <LinkButton onClick={handleBankAccountDrawer}>{t('whatDoesItMean')}</LinkButton>
              </OfferTermsCardRow>
            </OfferTermsCard>
          </div>
        ) : null}
        <FileChipContainer>
          {shortenedFileNames
            ?.sort((fileNameA) => (fileNameA.includes('offer') ? -1 : 1))
            ?.map((fileName) => (
              <FileChip
                dataTestId={fileName}
                key={fileName}
                chipNode={
                  <OfferFileCard variant={readonly ? 'light' : 'dark'} icon={doc}>
                    <OfferFileCardFileName>{t(getFileNameTranslation(fileName ?? ''))}</OfferFileCardFileName>
                    <OfferFileCardButton doNotTruncate>{t('open')}</OfferFileCardButton>
                  </OfferFileCard>
                }
                filename={fileName}
                onPreview={() => handleDownload(fileName)}
              />
            ))}
          {isFetching ? <OfferDocumentSkeleton /> : null}
          {isFetching && applicationDetails.type !== 'FACTORING' ? <OfferDocumentSkeleton /> : null}
        </FileChipContainer>
      </OfferStatusContentInfo>
      {readonly ? null : (
        <OfferStatusActions>
          <Button
            data-testid="accept-offer-button"
            disabled={isSubmitting}
            submitting={isSubmitting && decisionStatus === 'success'}
            onClick={onSubmit('success')}
            size="M"
            media={{
              maxPhone: { size: 'XL' }
            }}
          >
            {t('acceptYourOffer')}
          </Button>
          <Button
            data-testid="reject-offer-button"
            onClick={() => setShowPushStatus(true)}
            disabled={isSubmitting}
            size="M"
            media={{
              maxPhone: { size: 'XL' }
            }}
            variant="Stroked"
          >
            {t('rejectOffer')}
          </Button>
        </OfferStatusActions>
      )}
    </OfferStatusInnerContent>
  );

  if (readonly) {
    return (
      <>
        <AsideMenuTopBar
          handleClose={handleDrawerClose}
          topbarContent={
            <OfferDrawerHeader>
              <Title>{t('offer')}</Title>
            </OfferDrawerHeader>
          }
        />
        <OfferStatusContent>{contents}</OfferStatusContent>
      </>
    );
  }

  const resolvedNotificationVariant =
    isParallelFlowEnabled && decisionStatus === 'success' ? 'successNextSteps' : decisionStatus;

  return (
    <>
      <OfferStatusPushModal
        open={showPushStatus}
        onClose={() => {
          setShowPushStatus(false);
        }}
        skipThisOpportunity={onSubmit('error')}
        takeAnotherLook={() => setShowPushStatus(false)}
      />
      <OfferStatusConfirmationModal
        variant={resolvedNotificationVariant}
        open={
          showConfirmationStatus &&
          ((!ondatoStatusIsFetching && !isUninitialized) || decisionStatus === 'error')
        }
        onClose={() => setShowConfirmationStatus(false)}
        applicationId={applicationId}
      />
      <OverlayView direction="right" open={isOpen} onClose={handleClose} lowerZIndex>
        {contents}
      </OverlayView>
    </>
  );
};
