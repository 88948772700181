import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { clearUserCRUDMessage, setUserCRUDErrorMessage, setUserCRUDSuccessMessage } from 'features/app/slice';
import {
  useCreateRelationsMutation,
  useCreateUserMutation,
  useLazyGetUserByEmailQuery
} from 'features/users/slice';
import { useAppDispatch } from 'hooks/redux/hooks';
import { UserData } from 'services/RbacService/typings';

import { UserRole } from '../../roles/roles';

type UserPayload = UserData & { userEmails: string[]; role: UserRole };

export enum ROLES {
  admin = 1,
  viewer,
  editor,
  approver
}

const createUserPayload = (form: UserPayload) => {
  const payload: Partial<UserPayload> = { ...form };

  delete payload.userEmails;
  delete payload.role;

  payload.userLanguage = payload.userCountry;

  return payload;
};

const useTriggerCreateUser = (customerId?: number, _onSuccess?: () => unknown, onError?: () => unknown) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [triggerGetUserByEmailQuery] = useLazyGetUserByEmailQuery();
  const [triggerRelationCreation] = useCreateRelationsMutation();
  const [triggerUserCreation] = useCreateUserMutation();

  const createUser = async (newUserInfo: UserPayload | null) => {
    if (newUserInfo && customerId) {
      if (!isSubmitting) {
        setIsSubmitting(true);
        dispatch(clearUserCRUDMessage());

        try {
          let userId;

          try {
            const response = await triggerGetUserByEmailQuery({ email: newUserInfo.userEmail }).unwrap();

            if (response?.userId) {
              userId = response.userId;
            }
          } catch (err) {
            const errorResponse = err as { status: number; message: string };

            if (errorResponse?.status !== 404 && errorResponse.message !== 'USER_NOT_FOUND') {
              throw err;
            } else {
              const payload = createUserPayload(newUserInfo) as UserData;

              const newlyCreatedUser = await triggerUserCreation(payload).unwrap();

              userId = newlyCreatedUser.userId;
            }
          }

          if (userId) {
            await triggerRelationCreation({
              assignedRoles: [ROLES[newUserInfo.role as keyof typeof ROLES]],
              customerId,
              default: false,
              isDefault: false,
              userEntryId: userId,
              userId
            }).unwrap();
          } else {
            throw new Error('missing required ids to continue creation flow');
          }

          dispatch(setUserCRUDSuccessMessage({ action: t('added') }));
        } catch {
          dispatch(setUserCRUDErrorMessage({ action: t('added') }));
          onError?.();
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  return { isSubmitting, createUser };
};

export default useTriggerCreateUser;
