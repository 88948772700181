import { ReactNode, useEffect, useId, useMemo, useRef, useState } from 'react';

import chevronLeft from '../../assets/icons/chevronLeft.svg';
import close from '../../assets/icons/close.svg';
import { LinkButton } from '../link';

import DragablePill from './dragablePill';
import { SwipeHandlersOptions } from './hooks';
import { TitleAndActionsContainer, TitleAndActionsInnerRow } from './layout.styles';

type Props = {
  topbarContent?: ReactNode;
  handleClose: () => unknown;
  inner?: boolean;
};

export const AsideMenuTopBar = ({ topbarContent, inner, handleClose }: Props) => {
  const id = useId().replaceAll(':', '');
  const topBarRef = useRef<HTMLDivElement>(null);
  const swipeAreaRef = useRef<HTMLDivElement | null>(null);

  const [childrenHeight, setChildrenHeight] = useState(0);

  useEffect(() => {
    const swipableElements = document.querySelectorAll('[data-swipable-area="true"]');

    const swipeAreaElement = Array.from(swipableElements).find((element) => {
      if (element.querySelector(`#${topBarRef.current?.id}`)) {
        return true;
      }
    });

    if (swipeAreaElement) {
      setChildrenHeight(swipeAreaElement.clientHeight);
      swipeAreaRef.current = swipeAreaElement as HTMLDivElement;
    }
  }, [topBarRef.current]);

  const swipeOptions = useMemo((): SwipeHandlersOptions => {
    return {
      thresholdForConfirm: childrenHeight * 0.25,
      onSwipeConfirm: handleClose
    };
  }, [childrenHeight]);

  return (
    <TitleAndActionsContainer id={`aside-drawer-${id}`} ref={topBarRef}>
      <DragablePill swipeAreaRef={swipeAreaRef} swipeOptions={swipeOptions} />
      <LinkButton
        color="Black"
        iconOnly
        icons={{ right: inner ? chevronLeft : close }}
        onClick={handleClose}
      />
      <TitleAndActionsInnerRow>{topbarContent}</TitleAndActionsInnerRow>
    </TitleAndActionsContainer>
  );
};
