import { useTranslation } from 'react-i18next';

import { StatusChip } from 'components';
import { StatusType } from 'design/designVariables';
import { selectApplicationsList } from 'features/applications/selectors';
import { Status } from 'features/applications/types/applicationsTypes';
import { useAppSelector } from 'hooks/redux/hooks';

const statusToChipColorMap: Record<Status | 'NA', keyof StatusType> = {
  INITIAL: 'gray',
  ADDITIONAL_INFORMATION_NEEDED: 'yellow',
  ASSESSMENT_ONGOING: 'lightBlue',
  SUBMITTED_FOR_VOTING: 'lightBlue',
  OFFER_SENT: 'green',
  OFFER_ACCEPTED: 'green',
  OFFER_REJECTED: 'red',
  KYC_WAITING: 'green',
  KYC_COMPLETED: 'green',
  KYC_REJECTED: 'green',
  KYC_NOT_REQUIRED: 'green',
  CONTRACT_PREPARED: 'green',
  NA: 'white',
  NOT_FILLED: 'white',
  REJECTED: 'white',
  VOTING_COMPLETED: 'lightBlue',
  OFFER_GENERATED: 'green',
  WAITING_FOR_INFO: 'lightBlue',
  ADDITIONAL_INFO_SUBMITTED: 'lightBlue',
  CONTRACT_PREPARATION_IN_PROGRESS: 'green',
  CONTRACT_SIGNED: 'green',
  CONTRACT_PREPARATION_FAILED: 'green',
  INACTIVE: 'white',
  DELETED: 'white',
  REFUSED_BY_CLIENT: 'white'
};

const statusesTranslations: Record<Status | 'NA', string> = {
  INITIAL: 'notSubmitted',
  ASSESSMENT_ONGOING: 'inReview',
  ADDITIONAL_INFORMATION_NEEDED: 'docsRequired',
  SUBMITTED_FOR_VOTING: 'inReview',
  OFFER_SENT: 'offerSubmitted',
  OFFER_ACCEPTED: 'offerAccepted',
  OFFER_REJECTED: 'offerRejected',
  KYC_COMPLETED: 'offerAccepted',
  KYC_WAITING: 'offerAccepted',
  KYC_REJECTED: 'offerAccepted',
  KYC_NOT_REQUIRED: 'offerAccepted',
  CONTRACT_PREPARED: 'offerAccepted',
  NA: 'NA',
  REJECTED: 'NA',
  NOT_FILLED: 'inReview',
  VOTING_COMPLETED: 'inReview',
  OFFER_GENERATED: 'inReview',
  WAITING_FOR_INFO: 'docsRequired',
  ADDITIONAL_INFO_SUBMITTED: 'docsRequired',
  CONTRACT_PREPARATION_IN_PROGRESS: 'offerAccepted',
  CONTRACT_SIGNED: 'offerAccepted',
  CONTRACT_PREPARATION_FAILED: 'offerAccepted',
  INACTIVE: 'NA',
  DELETED: 'NA',
  REFUSED_BY_CLIENT: 'NA'
};

export const ApplicationStatus = ({ applicationId, id }: { applicationId?: string; id?: string }) => {
  const { t } = useTranslation();

  const applications = useAppSelector(selectApplicationsList);

  const application = applications?.find(
    (application) => application.capId === id || application.applicationId === applicationId
  );

  const applicationStatus = application?.status ?? 'INITIAL';

  return (
    <StatusChip color={statusToChipColorMap[applicationStatus]}>
      {t(statusesTranslations[applicationStatus])}
    </StatusChip>
  );
};
