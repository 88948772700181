import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { LinkButton } from 'components';
import { Layout } from 'components/pageV2';
import { SideNavigation } from 'features/app/components';
import { AdminBanner } from 'features/app/components/adminBanner';
import { ScrollStateContextProvider } from 'features/app/context';
import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { selectIsRegularUser } from 'features/auth/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { UpdateCompanyProfileCard } from 'features/onboarding/components';
import { DrawersSwitch } from 'features/users/components';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';

import { Company } from './company';
import Documents from './documents/documents';
import { MyInformation } from './information/myInformation';
import LanguageSelector from './language/languageSelector';
import { Section, SectionCard, SectionHeader } from './settings.styles';
import { Users } from './users/users';

type Props = {
  children: ReactNode;
};

const RenderForVerifiedUser = ({ children }: Props) => {
  const isRegularUser = useAuthSelector<typeof selectIsRegularUser>(selectIsRegularUser);
  const isVerifiedCompany = useAppSelector(selectIsActiveCompanyVerified);

  if (isRegularUser && isVerifiedCompany) {
    return <>{children}</>;
  }

  return null;
};

export const Settings = () => {
  const { t } = useTranslation();

  const { showRightSideDrawer, toggleRightSideDrawer, hideSideDrawer, setActiveDrawer } = useNavContext();

  const showRolesDrawer = () => {
    toggleRightSideDrawer?.({ drawerId: 'roles' });
  };

  return (
    <ScrollStateContextProvider>
      <Layout
        onDrawerStateChange={(visible) => {
          if (!visible) {
            hideSideDrawer?.();
            setActiveDrawer?.({});
          }
        }}
        rightSideDrawerOpen={showRightSideDrawer}
        asideMenuContent={<DrawersSwitch />}
        topBanner={<AdminBanner />}
        sideNavContent={<SideNavigation />}
      >
        <Section>
          <UpdateCompanyProfileCard />
        </Section>
        <Section>
          <SectionHeader>{t('language')}</SectionHeader>
          <LanguageSelector />
        </Section>
        <RenderForVerifiedUser>
          <Section>
            <SectionHeader>{t('myInformation')}</SectionHeader>
            <SectionCard>
              <MyInformation />
            </SectionCard>
          </Section>
          <Section>
            <SectionHeader>{t('company')}</SectionHeader>
            <SectionCard>
              <Company />
            </SectionCard>
          </Section>
        </RenderForVerifiedUser>
        <Section>
          <SectionHeader>{t('documents')}</SectionHeader>
          <SectionCard>
            <Documents />
          </SectionCard>
        </Section>
        <RenderForVerifiedUser>
          <Section>
            <SectionHeader>
              {t('users')}
              <LinkButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  showRolesDrawer();
                }}
              >
                <Icons.ExclamationCircle />
              </LinkButton>
            </SectionHeader>
            <Users />
          </Section>
        </RenderForVerifiedUser>
      </Layout>
    </ScrollStateContextProvider>
  );
};
