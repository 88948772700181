import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusChip } from 'components';
import { getSmeBankPromo } from 'defaultConfiguration/getSmeBankPromo';
import { useLazyFetchApplicationDetails } from 'features/application/hooks';
import { selectApplicationData } from 'features/application/selectors';
import { selectVerificationApplication } from 'features/applications/selectors';
import { useFetchBankAccountInfoQuery } from 'features/onboarding/api';
import { useExternalSessionStorage, useGetOnboardingEnabledConfig } from 'features/onboarding/hooks';
import { isReadyForOndato } from 'features/onboarding/hooks/useCurrentFlowStep';
import { useApplicationSelector, useAppSelector } from 'hooks/redux/hooks';

import BankCard from '../bankCard/bankCard';

import StepCard from './stepCard';

type Props = {
  step: 'setUpBankAccount';
  enabled?: boolean;
  loading?: boolean;
  alternativeBankAccountEnabled?: boolean;
  secondaryAction?: boolean;
  onInactiveClick?: () => unknown;
  openBankAccountDrawer?: (visible: boolean) => void | undefined;
};

const BankStepCard = ({
  alternativeBankAccountEnabled,
  step,
  enabled,
  loading,
  secondaryAction,
  openBankAccountDrawer,
  onInactiveClick
}: Props) => {
  const { t } = useTranslation();

  const [status] = useExternalSessionStorage(step);

  const application = useAppSelector(selectVerificationApplication);
  const applicationDetails = useApplicationSelector<typeof selectApplicationData>(selectApplicationData);

  const { ondatoAvailableCAPStatus } = useGetOnboardingEnabledConfig();

  const { loansAdditionalInfo } = applicationDetails ?? {};

  const shouldOpenBankAccount = loansAdditionalInfo?.bankAccountOpen === 'WILL_OPEN';

  const notReadyForOndato =
    !application.applicationId || !isReadyForOndato(application.status, ondatoAvailableCAPStatus);

  const { currentData: bankAccountInfo } = useFetchBankAccountInfoQuery(
    { applicationId: application.applicationId ?? '' },
    { skip: notReadyForOndato }
  );

  const [fetchApplicationByIdAsync] = useLazyFetchApplicationDetails();

  useEffect(() => {
    if (application?.applicationId) {
      fetchApplicationByIdAsync({
        capId: application.capId ?? '',
        applicationId: application.applicationId ?? ''
      });
    }
  }, [application?.applicationId, application.capId]);

  const iban = bankAccountInfo?.iban ?? '';

  const smeBankPromoList = getSmeBankPromo();
  const enableSmeBankPromo = smeBankPromoList.includes(application?.companyCountry ?? '');

  const title = shouldOpenBankAccount
    ? 'openBankAccountWithSmeBank'
    : enableSmeBankPromo
      ? 'setUpBankAccount'
      : 'addBankAccount';

  const isDone = iban && !enableSmeBankPromo;

  const statusChip =
    status || isDone ? (
      <StatusChip color={isDone ? 'green' : 'gray'}>{t(isDone ? 'done' : status)}</StatusChip>
    ) : null;

  return (
    <StepCard
      onInactiveClick={onInactiveClick}
      loading={loading}
      disabled={!enabled}
      title={t(title)}
      explanation={shouldOpenBankAccount}
      StatusChip={statusChip}
      openBankAccountDrawer={openBankAccountDrawer}
    >
      <BankCard
        iban={iban}
        alternativeBankAccountEnabled={alternativeBankAccountEnabled}
        step={step}
        secondaryAction={secondaryAction}
        shouldOpenBankAccount={shouldOpenBankAccount}
      />
    </StepCard>
  );
};

export default BankStepCard;
