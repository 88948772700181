import { useTranslation } from 'react-i18next';

import { Column, Grid, SecondaryText, RegularText } from 'components';
import { selectActiveCustomer } from 'features/auth/selectors';
import { useAuthSelector } from 'hooks/redux/hooks';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';

import { ContentContainer } from './company.styles';

const Company = () => {
  const { t, i18n } = useTranslation();

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const { customerName, customerId, customerCountryId } = activeCustomer || {};
  const countryName = getCountryDisplayName(customerCountryId || '', i18n.language as Languages);

  return (
    <ContentContainer>
      <Grid>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <SecondaryText>{t('companyName')}</SecondaryText>
          <RegularText>{customerName}</RegularText>
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <SecondaryText>{t('placeholderCompanyCode')}</SecondaryText>
          <RegularText>{customerId}</RegularText>
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <SecondaryText>{t('country')}</SecondaryText>
          <RegularText>{countryName}</RegularText>
        </Column>
      </Grid>
    </ContentContainer>
  );
};

export default Company;
