import { InputHTMLAttributes, ReactNode, useId } from 'react';

import { ChipOptionLabel, ChipProps, HiddenInput } from './chipOption.styles';

type NativeInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

type Props = {
  children: ReactNode;
} & Partial<ChipProps> &
  NativeInputProps;

const ChipOption = ({
  children,
  on = 'dark',
  rounded = true,
  size = 'M',
  variant = 'radio',
  ...rest
}: Props) => {
  const uniqueId = useId();

  const inputId = `input-${uniqueId.replaceAll(':', '')}`;

  return (
    <ChipOptionLabel
      data-testid={`${rest.name}-${rest.value}`}
      on={on}
      rounded={rounded}
      htmlFor={inputId}
      variant={variant}
      size={size}
    >
      {children}
      <HiddenInput {...rest} id={inputId} type={variant} />
    </ChipOptionLabel>
  );
};

export default ChipOption;
