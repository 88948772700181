import { forwardRef, useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import nut from 'assets/icons/nut.svg';
import { BlueCheckmark } from 'components';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { resetApplicationState, setIsNewCompany } from 'features/application/slice';
import { selectActiveCompany } from 'features/applications/selectors';
import { selectIsCustomerAdmin } from 'features/auth/selectors';
import { setActiveCompany, setActiveCustomerById, setIsRefreshingToken } from 'features/auth/slice';
import { Company } from 'features/auth/types/authTypes';
import { Logout } from 'features/nav/components/logoutButton';
import { useTriggerExistingCustomerOnboardingStatusFetch } from 'features/onboarding/hooks';
import { useAppDispatch, useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { AppService } from 'services';
import { refreshAuthLogic } from 'services/RestClient';

import {
  BottomActions,
  SettingsMenuItem,
  SideNavigationMenuItemContent,
  SettingsMenuItemWrapper
} from '../sideNavigation/sideNavigation.styles';

import CompanyNotFound from './companyNotFound';
import {
  AddCompanyOption,
  CloseButton,
  CompanyOption,
  CompanyOptionContent,
  CompanySelect,
  CompanyStatus,
  CompanySwitchCard,
  CompanySwitchTitle,
  CompanyTitle,
  HeroShimmer,
  Shimmer,
  CompanySearchInput
} from './companySwitch.styles';

type Props = {
  companies: Company[];
  onSelect?: (id: string) => unknown;
  loading?: boolean;
  onSearchChange?: (searchString: string) => unknown;
  searchQuery?: string;
  dataTestId?: string;
};

const companyStatusChecker = (companies: Company[]) => (id: string) => {
  const company = companies.find((company) => company.id === id);

  if (!company) {
    return false;
  }

  return company.roles?.length === 0 || company.roles?.includes('PROSPECT');
};

const CompanySwitch = forwardRef<HTMLDivElement, Props>(
  ({ companies, onSelect, searchQuery, onSearchChange, loading, dataTestId }, ref) => {
    const uniqueId_prefix = useId();
    const { isFeatureEnabled } = useRemoteConfigContext();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [initialSetup, setInitialSetup] = useState(false);

    const selectedCompanyRef = useRef<HTMLLabelElement>(null);
    const selectScrollAreaRef = useRef<HTMLDivElement>(null);

    const [filteredCompanies, setFilteredCompanies] = useState(companies);

    useTriggerExistingCustomerOnboardingStatusFetch();

    const isAdmin = useAuthSelector<typeof selectIsCustomerAdmin>(selectIsCustomerAdmin);
    const isCompanyUnverified = companyStatusChecker(companies);
    const activeCompany = useAppSelector(selectActiveCompany);

    const verificationFlowEnabled = isFeatureEnabled('onboardingSSO') && !isAdmin;

    const handleSearch = (searchString: string) => {
      onSearchChange?.(searchString);
    };

    const addNewCompany = () => {
      dispatch(resetApplicationState());
      dispatch(setIsNewCompany(true));
      navigate('/new-application?newcompany=true');
    };

    const switchToCompany = (id: string) => {
      const selectedCompany = companies.find((company) => company.id === id);

      if (selectedCompany) {
        dispatch(setActiveCompany(selectedCompany));
        navigate('/dashboard/verification');
      }
    };

    const switchToCustomer = async (id: string) => {
      const selectedCompany = companies.find((company) => company.id === id);

      await refreshAuthLogic(null, id, true);

      if (selectedCompany) {
        dispatch(setActiveCustomerById(id));
        dispatch(setActiveCompany(selectedCompany));
        navigate('/dashboard/overview');
      }
    };

    const handleSelect = async (id: string) => {
      try {
        dispatch(setIsRefreshingToken(true));
        const unverified = isCompanyUnverified(id);

        if (unverified) {
          switchToCompany(id);
        } else {
          await switchToCustomer(id);
        }
      } catch (e) {
        AppService.reportError({
          message: 'Error while switching company',
          eventId: 'company-switch-error'
        });
      } finally {
        dispatch(setIsRefreshingToken(false));
        onSelect?.(id);
      }
    };

    const onCompanySelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const id = e.currentTarget.value;

        await handleSelect(id);
      }
    };

    useEffect(() => {
      if (searchQuery && searchQuery.length >= 3) {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();

        setFilteredCompanies(
          companies.filter(
            (company) =>
              company.name.toLowerCase().includes(lowerCaseSearchQuery) ||
              company.id.toLowerCase().includes(lowerCaseSearchQuery)
          )
        );
      } else {
        setFilteredCompanies(companies);
      }
    }, [searchQuery, companies.length]);

    useEffect(() => {
      if (!initialSetup) {
        if (selectedCompanyRef.current && selectScrollAreaRef.current) {
          selectedCompanyRef.current.scrollIntoView({ behavior: 'instant' as ScrollBehavior });
          setInitialSetup(true);
        }
      }
    }, [selectedCompanyRef.current]);

    return (
      <CompanySwitchCard
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CloseButton onClick={() => onSelect?.('')}></CloseButton>
        {isAdmin ? (
          <CompanySearchInput
            placeholder={t('Search by company name')}
            value={searchQuery ?? ''}
            onChange={handleSearch}
          />
        ) : null}
        <CompanySwitchTitle>{t('myCompanies')}</CompanySwitchTitle>
        <CompanySelect ref={selectScrollAreaRef} data-testid={dataTestId}>
          {filteredCompanies.length ? (
            filteredCompanies.map((company) => {
              const unverified = isCompanyUnverified(company.id);

              return (
                <CompanyOption
                  data-testid={`company-option-${company.id}`}
                  ref={activeCompany?.id === company.id ? selectedCompanyRef : undefined}
                  onClick={() => {
                    setTimeout(() => {
                      onSelect?.(company.id);
                    }, 200);
                  }}
                  user={!company.id}
                  key={company.id}
                  htmlFor={company.id}
                >
                  <CompanyOptionContent>
                    <CompanyTitle>{company.name}</CompanyTitle>
                    {unverified ? <CompanyStatus>{t('unverified')}</CompanyStatus> : null}
                  </CompanyOptionContent>
                  <BlueCheckmark
                    onChange={onCompanySelect}
                    name={`${uniqueId_prefix}_company`}
                    id={company.id}
                    value={company.id}
                    checked={activeCompany?.id === company.id}
                  />
                </CompanyOption>
              );
            })
          ) : (
            <CompanyNotFound />
          )}
          {loading ? (
            <CompanyOption hideHero>
              <CompanyOptionContent>
                <HeroShimmer />
                <Shimmer height="1.5rem" width="7.5rem" />
              </CompanyOptionContent>
              <Shimmer height="1.5rem" width="5.25rem" />
            </CompanyOption>
          ) : null}
        </CompanySelect>
        {verificationFlowEnabled ? (
          <AddCompanyOption onClick={addNewCompany}>
            <CompanyTitle>{t('addNewCompany')}</CompanyTitle>
          </AddCompanyOption>
        ) : (
          <span></span>
        )}
        <BottomActions>
          <SettingsMenuItem to="/settings">
            <SideNavigationMenuItemContent icon={nut}>{t('settings')}</SideNavigationMenuItemContent>
          </SettingsMenuItem>
          <SettingsMenuItemWrapper htmlFor="logout-button" to="/">
            <Logout />
          </SettingsMenuItemWrapper>
        </BottomActions>
      </CompanySwitchCard>
    );
  }
);

export default CompanySwitch;
