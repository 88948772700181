import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AsideMenuTopBar } from 'components/pageV2';
import { DrawerTopbarTitle } from 'components/pageV2/layout.styles';
import { useKeyboardHandler } from 'features/app/hooks';
import { useNavContext } from 'features/nav/context/navContext';

import {
  DeleteButton,
  DeleteButtonContainer,
  DrawerContainer,
  DrawerForm,
  InnerContainer,
  SaveButton,
  SaveButtonContainer
} from './drawer.styles';

type Props = {
  children: ReactNode;
  buttonLabel: string;
  deleteButtonLabel?: string;
  onSave: () => Promise<unknown>;
  onClose?: () => unknown;
  onDelete?: () => unknown;
  disabledDelete?: boolean;
  isSubmitting?: boolean;
  header?: string;
  isValid?: boolean;
  inner?: boolean;
};

const Drawer = ({
  children,
  buttonLabel,
  onSave,
  onClose,
  onDelete,
  disabledDelete,
  deleteButtonLabel,
  isSubmitting,
  header,
  inner,
  isValid
}: Props) => {
  const { t } = useTranslation();
  const { toggleRightSideDrawer, showRightSideDrawer } = useNavContext();

  const handleClose = () => {
    onClose?.();

    if (!inner) {
      toggleRightSideDrawer?.();
    }
  };

  const handleSave = async () => {
    await onSave();
  };

  useEffect(() => {
    if (!showRightSideDrawer) {
      onClose?.();
    }
  }, [showRightSideDrawer]);

  useKeyboardHandler(handleClose, handleSave);

  return (
    <>
      <AsideMenuTopBar
        inner={inner}
        handleClose={handleClose}
        topbarContent={header ? <DrawerTopbarTitle>{header}</DrawerTopbarTitle> : null}
      />
      <DrawerContainer>
        <DrawerForm>
          <InnerContainer>{children}</InnerContainer>
          {onDelete && !disabledDelete ? (
            <DeleteButtonContainer>
              <DeleteButton disabled={isSubmitting} onClick={onDelete}>
                {t(deleteButtonLabel ?? 'delete')}
              </DeleteButton>
            </DeleteButtonContainer>
          ) : null}
          <SaveButtonContainer>
            <SaveButton
              type="button"
              invalid={!isValid}
              media={{ maxPhone: { size: 'L' } }}
              onClick={handleSave}
              disabled={isSubmitting}
              submitting={isSubmitting}
            >
              {buttonLabel}
            </SaveButton>
          </SaveButtonContainer>
        </DrawerForm>
      </DrawerContainer>
    </>
  );
};

export default Drawer;
