import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import nut from 'assets/icons/nut.svg';
import { ScreenSize } from 'components';
import { MobileView } from 'components/pageV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { selectActiveCompany, selectIsActiveCompanyVerified } from 'features/applications/selectors';
import {
  selectActiveCustomer,
  selectIsRegularUser,
  selectIsUserAuthenticated,
  selectIsUserGhost
} from 'features/auth/selectors';
import { SupportedCountries } from 'features/auth/types/authTypes';
import { Logout } from 'features/nav/components/logoutButton';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';

import { TopBar } from '../../../../components/topBar/TopBar';
import CompanySwitchButton from '../companySwitch/companySwitchButton';

import links, { LinksSection } from './links';
import {
  BottomActions,
  Section,
  SectionTitle,
  Sections,
  SettingsMenuItem,
  SettingsMenuItemWrapper,
  SideNavigationContainer,
  SideNavigationMenuItem,
  SideNavigationMenuItemContent
} from './sideNavigation.styles';

const SideNavigation = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useRemoteConfigContext();
  const isAuthenticated = useAuthSelector<typeof selectIsUserAuthenticated>(selectIsUserAuthenticated);
  const isGhostUser = useAuthSelector<typeof selectIsUserGhost>(selectIsUserGhost);
  const isRegularUser = useAuthSelector<typeof selectIsRegularUser>(selectIsRegularUser);
  const customerInfo = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const isCompanyVerified = useAppSelector(selectIsActiveCompanyVerified);
  const activeCompany = useAppSelector(selectActiveCompany);

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const checkIfLinkIsVisible = (link: LinksSection) => {
    const featureEnabled = !link.featureName || isFeatureEnabled(link.featureName);
    const scopeEnabled =
      !link.scope ||
      (isGhostUser && link.scope.includes('ghost')) ||
      (isRegularUser && link.scope.includes('regular'));

    const companyRoleEnabled =
      !link.role || link.role.map((role) => activeCompany?.roles?.includes(role)).some(Boolean);

    const countryEnabled =
      !link.country || link.country.includes(customerInfo?.customerCountryId as SupportedCountries);

    return featureEnabled && scopeEnabled && countryEnabled && companyRoleEnabled;
  };

  const MenuContent = (
    <Sections data-testid="side-navigation-menu-main-content">
      {links.map((linkSection, index) => {
        if (checkIfLinkIsVisible(linkSection)) {
          return (
            <Section key={`${linkSection.segment}-${index}`}>
              {linkSection.title ? <SectionTitle>{t(linkSection.title)}</SectionTitle> : null}
              {linkSection?.links?.map((link, index) => {
                if (checkIfLinkIsVisible(link)) {
                  return (
                    <SideNavigationMenuItem
                      data-testid={`side-navigation-menu-link-${linkSection.segment}-${link.segment}`}
                      to={`/${linkSection.segment}/${link.segment}`}
                      key={`${link.segment}-${index}`}
                    >
                      <SideNavigationMenuItemContent icon={link.icon}>
                        {t(link.title || link.segment)}
                      </SideNavigationMenuItemContent>
                    </SideNavigationMenuItem>
                  );
                }

                return null;
              })}
              {!isCompanyVerified && (
                <SettingsMenuItem to="/settings">
                  <SideNavigationMenuItemContent icon={nut}>{t('settings')}</SideNavigationMenuItemContent>
                </SettingsMenuItem>
              )}
            </Section>
          );
        }

        return null;
      })}
      <BottomActions>
        {isCompanyVerified && (
          <SettingsMenuItem to="/settings">
            <SideNavigationMenuItemContent icon={nut}>{t('settings')}</SideNavigationMenuItemContent>
          </SettingsMenuItem>
        )}
        {isFeatureEnabled('companySwitchV2') ? (
          <SettingsMenuItemWrapper htmlFor="logout-button" to="/">
            <Logout />
          </SettingsMenuItemWrapper>
        ) : null}
      </BottomActions>
    </Sections>
  );

  const onBurgerClick = () => setBurgerMenuOpen(!burgerMenuOpen);

  return (
    <>
      <SideNavigationContainer>
        <TopBar
          isAuthenticated={isAuthenticated}
          companySwitch={<CompanySwitchButton />}
          onToggleAcionClick={onBurgerClick}
        />
        <ScreenSize data-testid="side-navigation-menu-desktop" fullHeight desktop desktopXL>
          {MenuContent}
        </ScreenSize>
      </SideNavigationContainer>
      <MobileView
        dataTestId="side-navigation-menu-mobile"
        onClose={() => setBurgerMenuOpen(false)}
        open={burgerMenuOpen}
      >
        {MenuContent}
      </MobileView>
    </>
  );
};

export default SideNavigation;
