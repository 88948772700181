import { useTranslation } from 'react-i18next';

import { Column, Grid, SecondaryText, RegularText } from 'components';
import { selectCustomerInformation, selectUserData } from 'features/auth/selectors';
import { PhoneNumber } from 'features/users/components';
import { useAuthSelector } from 'hooks/redux/hooks';

import { ContentContainer } from './myInformation.styles';

export const MyInformation = () => {
  const { t } = useTranslation();

  const customerInfo = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const { email } = useAuthSelector(selectUserData);

  const { userFirstName, userLastName } = customerInfo || {};

  return (
    <ContentContainer>
      <Grid>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <SecondaryText>{t('name')}</SecondaryText>
          <RegularText>{userFirstName}</RegularText>
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <SecondaryText>{t('lastName')}</SecondaryText>
          <RegularText>{userLastName}</RegularText>
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <SecondaryText>{t('email')}</SecondaryText>
          <RegularText>{email}</RegularText>
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <PhoneNumber />
        </Column>
      </Grid>
    </ContentContainer>
  );
};
