import { AsideDrawer } from 'components';

import { UserRole } from '../roles/roles';

import RolesDrawer from './rolesDrawer';

type Props = {
  isOpen: boolean;
  onClose?: () => unknown;
  selectedRole?: UserRole;
};

const StandaloneRolesDrawer = ({ isOpen, onClose, selectedRole }: Props) => {
  return (
    <AsideDrawer portal={false} absolute activeIndex={1} swipeable isOpen={isOpen}>
      <RolesDrawer selectedRole={selectedRole} onClose={onClose} inner />
    </AsideDrawer>
  );
};

export default StandaloneRolesDrawer;
