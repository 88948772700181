import { ReactComponent as PlusCircleOutline } from './add.svg?react';
import { ReactComponent as ArrowLeft } from './arrowLeft.svg?react';
import { ReactComponent as Bin } from './bin.svg?react';
import { ReactComponent as BinWithBackground } from './binWithBackground.svg?react';
import { ReactComponent as BurgerMenu } from './burgerMenu.svg?react';
import { ReactComponent as BusinessLoan } from './businessLoan.svg?react';
import { ReactComponent as Calendar } from './calendar.svg?react';
import { ReactComponent as Camera } from './camera.svg?react';
import { ReactComponent as Chat } from './chat.svg?react';
import { ReactComponent as Check } from './check.svg?react';
import { ReactComponent as Checkmark } from './checkmark.svg?react';
import { ReactComponent as CheckmarkCircle } from './checkmarkCircle.svg?react';
import { ReactComponent as CheckMarkV2 } from './checkmarkV2.svg?react';
import { ReactComponent as ChevronDown } from './chevronDown.svg?react';
import { ReactComponent as ChevronLeft } from './chevronLeft.svg?react';
import { ReactComponent as ChevronRight } from './chevronRight.svg?react';
import { ReactComponent as Close } from './close.svg?react';
import { ReactComponent as CloseMedium } from './close24px.svg?react';
import { ReactComponent as ComputerOnDesk } from './computerOnDesk.svg?react';
import { ReactComponent as DirectionalArrows } from './directionalArrows.svg?react';
import { ReactComponent as Disbursement } from './disbursement.svg?react';
import { ReactComponent as Download } from './download.svg?react';
import { ReactComponent as Email } from './email.svg?react';
import { ReactComponent as Enter } from './enter.svg?react';
import { ReactComponent as Error } from './error.svg?react';
import { ReactComponent as ErrorSmall } from './errorSmall.svg?react';
import { ReactComponent as Excel } from './excel.svg?react';
import { ReactComponent as ExclamationCircle } from './exclamationCircle.svg?react';
import { ReactComponent as ExitDoor } from './exitDoor.svg?react';
import { ReactComponent as ExitDoorBackground } from './exitDoorBackground.svg?react';
import { ReactComponent as EyeOutline } from './eye.svg?react';
import { ReactComponent as Factoring } from './factoring_ic.svg?react';
import { ReactComponent as FAQ } from './faq.svg?react';
import { ReactComponent as Filter } from './filter.svg?react';
import { ReactComponent as Financing } from './financing.svg?react';
import { ReactComponent as FlexiFinance } from './flexiFinance.svg?react';
import { ReactComponent as Floppy } from './floppy.svg?react';
import { ReactComponent as Heart } from './heart.svg?react';
import { ReactComponent as HeartInArm } from './heartInArm.svg?react';
import { ReactComponent as HuggingFace } from './huggingFace.svg?react';
import { ReactComponent as Info } from './info.svg?react';
import { ReactComponent as Insights } from './insights.svg?react';
import { ReactComponent as InvoiceFinance } from './invoiceFinance.svg?react';
import { ReactComponent as Car } from './leasing.svg?react';
import { ReactComponent as Leasing } from './leasing_ic.svg?react';
import { ReactComponent as Loans } from './loans_ic.svg?react';
import { ReactComponent as Lock } from './lock.svg?react';
import { ReactComponent as LockInShield } from './lockInShield.svg?react';
import { ReactComponent as MagnificationGlass } from './magnificationGlass.svg?react';
import { ReactComponent as Mail } from './mail.svg?react';
import { ReactComponent as House } from './main.svg?react';
import { ReactComponent as ManualUpload } from './manualUpload.svg?react';
import { ReactComponent as MenuDots } from './menuDots.svg?react';
import { ReactComponent as Minus } from './minus.svg?react';
import { ReactComponent as MoneyBag } from './moneyBag.svg?react';
import { ReactComponent as MyApplications } from './myApplications.svg?react';
import { ReactComponent as NewTab } from './newTab.svg?react';
import { ReactComponent as Nut } from './nut.svg?react';
import { ReactComponent as Pantheon } from './pantheon.svg?react';
import { ReactComponent as PencilOutline } from './pencilOutline.svg?react';
import { ReactComponent as PenInContainer } from './penInContainer.svg?react';
import { ReactComponent as Person } from './person.svg?react';
import { ReactComponent as PersonalCard } from './personalCard.svg?react';
import { ReactComponent as PersonalId } from './personalId.svg?react';
import { ReactComponent as PersonMultiple } from './personMultiple.svg?react';
import { ReactComponent as Phone } from './phone.svg?react';
import { ReactComponent as Products } from './products.svg?react';
import { ReactComponent as QuestionaireCompleted } from './questionaireCompleted.svg?react';
import { ReactComponent as QuestionaireRejected } from './questionaireRejected.svg?react';
import { ReactComponent as QuestionaireWaiting } from './questionaireWaiting.svg?react';
import { ReactComponent as RBF } from './rbf.svg?react';
import { ReactComponent as ReceiptEdit } from './receiptedit.svg?react';
import { ReactComponent as RedX } from './redX.svg?react';
import { ReactComponent as RevenueBasedFinancing } from './revenueBasedFinancing.svg?react';
import { ReactComponent as RocketBig } from './rocketBig.svg?react';
import { ReactComponent as SpaceShip } from './spaceShip.svg?react';
import { ReactComponent as Star } from './star.svg?react';
import { ReactComponent as TeaCupLarge } from './teaCupLarge.svg?react';
import { ReactComponent as UploadOutline } from './uploadOutline.svg?react';
import { ReactComponent as UploadOutlineMedium } from './uploadOutlineMedium.svg?react';
import { ReactComponent as UserBlock } from './userBlock.svg?react';
import { ReactComponent as Warning } from './warning.svg?react';
import { ReactComponent as CheckmarkWhite } from './whiteCheckmark.svg?react';

export default {
  Star,
  BusinessLoan,
  FlexiFinance,
  Insights,
  InvoiceFinance,
  Car,
  House,
  MyApplications,
  Products,
  RBF,
  ArrowLeft,
  CheckmarkCircle,
  Checkmark,
  CheckMarkV2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  PlusCircleOutline,
  Close,
  PencilOutline,
  Bin,
  Person,
  ExclamationCircle,
  EyeOutline,
  UploadOutline,
  Factoring,
  Loans,
  Leasing,
  FAQ,
  Calendar,
  PersonMultiple,
  Chat,
  HuggingFace,
  Mail,
  RedX,
  Minus,
  Phone,
  Filter,
  Excel,
  Nut,
  ExitDoor,
  Pantheon,
  DirectionalArrows,
  CloseMedium,
  ReceiptEdit,
  QuestionaireWaiting,
  QuestionaireCompleted,
  QuestionaireRejected,
  RevenueBasedFinancing,
  ManualUpload,
  Download,
  PersonalCard,
  ComputerOnDesk,
  Error,
  Disbursement,
  MoneyBag,
  MagnificationGlass,
  Info,
  ExitDoorBackground,
  Warning,
  Check,
  Floppy,
  BinWithBackground,
  UploadOutlineMedium,
  NewTab,
  ErrorSmall,
  PenInContainer,
  Financing,
  Lock,
  BurgerMenu,
  MenuDots,
  PersonalId,
  Camera,
  RocketBig,
  UserBlock,
  Heart,
  LockInShield,
  HeartInArm,
  SpaceShip,
  Email,
  CheckmarkWhite,
  TeaCupLarge,
  Enter
};
