import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { selectActiveCompany } from 'features/applications/selectors';
import { selectIsCustomerAdmin } from 'features/auth/selectors';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import tracking from 'utils/tracking';

import { AdminToast, EyeIcon } from './adminBanner.styles';

const AdminBanner = () => {
  const { t } = useTranslation();

  const bannerRef = useRef<HTMLDivElement>(null);

  const activeCompany = useAppSelector(selectActiveCompany);
  const isAdminUser = useAuthSelector<typeof selectIsCustomerAdmin>(selectIsCustomerAdmin);

  useEffect(() => {
    const bordersOffsetInPx = 2;

    document.body.classList.toggle('with-top-banner', isAdminUser);

    if (bannerRef.current) {
      document.body.style.setProperty(
        '--spa-globals-top-banner-height',
        `${bannerRef.current.clientHeight + bordersOffsetInPx}px`
      );

      if (isAdminUser) {
        tracking.setIsAdmin();
      }
    }
  }, [isAdminUser, bannerRef.current]);

  return (
    <AdminToast
      customIcon={<EyeIcon />}
      ref={bannerRef}
      variant="info"
      header={t('youAreInPreviewMode', { companyName: activeCompany.name })}
      message={t('youAreInPreviewModeExplanation')}
      truncateText={false}
      animate={false}
      isVisible={isAdminUser}
    />
  );
};

export default AdminBanner;
