import {
  DeleteButtonShimmer,
  ExplanationAboutInvitationShimmer,
  InputShimmer,
  InputsContainer,
  RoleOptionShimmer,
  RolesTitleShimmer
} from './skeleton.styles';

const UserDrawerSkeleton = () => {
  return (
    <>
      <ExplanationAboutInvitationShimmer />
      <InputsContainer>
        <InputsContainer>
          <InputShimmer />
          <InputShimmer />
          <InputShimmer />
          <InputShimmer />
          <InputShimmer />
          <InputShimmer />
        </InputsContainer>
        <RolesTitleShimmer />
        <InputsContainer>
          <RoleOptionShimmer />
          <RoleOptionShimmer />
          <RoleOptionShimmer />
          <RoleOptionShimmer />
        </InputsContainer>
        <DeleteButtonShimmer />
      </InputsContainer>
    </>
  );
};

export default UserDrawerSkeleton;
