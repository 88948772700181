import { useTranslation } from 'react-i18next';

import { AmountText, LinkButton } from 'components';
import { Button } from 'components/buttonV2';
import { cards } from 'features/application/forms/selectProduct';
import { useFetchExistingCustomerApplicationOnboardingStatus } from 'features/applications/hooks';
import { selectApplicationsList } from 'features/applications/selectors';
import { Status } from 'features/applications/types/applicationsTypes';
import { useAppSelector } from 'hooks/redux/hooks';
import { OfferInfo, Type } from 'services/CapService/types';
import { camelCase } from 'utils';

import {
  Amount,
  ApplicationCardToast,
  ApplicationNumber,
  Body,
  Card,
  Header,
  HeaderIcon,
  HeaderIconAndText,
  HeaderLeftColumn,
  HeaderTextContent,
  ProductName
} from './applicationCard.styles';
import { ApplicationPrimaryAction } from './applicationPrimaryAction';
import { ApplicationStatus } from './applicationStatus';

export type ActionHandlers = Record<
  Actions,
  (id?: string, applicationId?: string, offer?: OfferInfo) => unknown
>;

export type DrawerInfo = {
  id?: string;
  applicationId?: string;
  applicationNumber?: string;
  type?: string;
  status?: Status;
  drawer: 'applicationDetails';
  viewOnly?: boolean;
};

type Props = {
  isActive?: boolean;
  product?: Type;
  id?: string;
  applicationId?: string;
  applicationNumber?: string;
  amount?: number;
  viewOnly?: boolean;
  onCardClick?: (info: DrawerInfo) => unknown;
  closeDrawer?: () => unknown;
  drawerId?: string;
};

export type Actions = 'delete' | 'download';

const ApplicationCard = ({
  id,
  applicationId,
  isActive,
  applicationNumber,
  amount,
  onCardClick,
  viewOnly,
  product,
  closeDrawer,
  drawerId
}: Props) => {
  const { t } = useTranslation();

  const applications = useAppSelector(selectApplicationsList);

  const application = applications?.find(
    (application) => application.capId === id || application.applicationId === applicationId
  );

  const applicationStatus = application?.status ?? 'INITIAL';

  const { onboardingForApplicationNeeded } =
    useFetchExistingCustomerApplicationOnboardingStatus(applicationStatus);

  const icon = cards.find((card) => card.type === product || product?.includes(card.type))?.iconToDisplay;

  const handleCardClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (closeDrawer && (id === drawerId || applicationId === drawerId)) {
      closeDrawer();
    } else if (onCardClick) {
      onCardClick({
        drawer: 'applicationDetails',
        id,
        applicationId,
        applicationNumber
      });
    }
  };

  if (!product) return null;

  const infoMessageId = onboardingForApplicationNeeded
    ? 'updateProfileToastMessage'
    : 'offerSubmittedToastMessage';

  const card = (
    <Card data-testid={`application-card-${applicationNumber ?? id}`} isActive={isActive}>
      <Header>
        <HeaderLeftColumn>
          <HeaderIconAndText>
            <HeaderIcon>{icon}</HeaderIcon>
            <HeaderTextContent>
              <ProductName>{t(camelCase(product ?? '').toLowerCase())}</ProductName>
              {applicationNumber ? <ApplicationNumber>{applicationNumber}</ApplicationNumber> : null}
            </HeaderTextContent>
          </HeaderIconAndText>
          {viewOnly ? null : <ApplicationStatus id={id} applicationId={applicationId} />}
        </HeaderLeftColumn>
      </Header>
      {viewOnly ? null : (
        <Body>
          <ApplicationCardToast
            message={t(infoMessageId)}
            isVisible={applicationStatus === 'ASSESSMENT_ONGOING'}
          />
          <Amount>
            <AmountText>{amount}</AmountText>
          </Amount>
          <ApplicationPrimaryAction applicationId={applicationId} id={id} />
        </Body>
      )}
      {viewOnly ? <Button color="LightBlack">{t('viewApplication')}</Button> : null}
    </Card>
  );

  if (onCardClick) {
    return (
      <LinkButton color="Black" onClick={handleCardClick} iconOnly>
        {card}
      </LinkButton>
    );
  }

  return card;
};

export default ApplicationCard;
