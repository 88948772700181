import { PaymentDetails } from 'services/CapService/types';

type FieldConfig = { min: number; max?: number };
type FieldConfigWithDefault = Required<FieldConfig> & { default: number };

type InterestRate = {
  [key: number]: FieldConfig;
};

type ResidualValueCeiling = {
  [key: number]: FieldConfig;
};

type LimitFields = keyof Omit<
  PaymentDetails,
  | 'leasingFormLink'
  | 'uniqueId'
  | 'monthlyPayment'
  | 'advancePayment'
  | 'residualValue'
  | 'residualValuePercentage'
>;

export type LeasingCalculatorInfo = {
  interestRates: InterestRate;
  residualValueCeiling: ResidualValueCeiling;
  genericFields: Record<LimitFields, FieldConfigWithDefault>;
};

const genericFields: Record<LimitFields, FieldConfigWithDefault> = {
  propertyPrice: { min: 5000, max: 200000, default: 15000 },
  term: { min: 12, max: 72, default: 36 },
  advancePaymentPercentage: { min: 10, max: 30, default: 20 }
};

const defaultInterestRate: InterestRate = {
  15.8: { min: 0 }
};

const defaultResidualValueCeiling: ResidualValueCeiling = {
  80: { min: genericFields.term.min, max: 23 },
  70: { min: 24, max: 35 },
  60: { min: 36, max: 47 },
  50: { min: 48, max: 59 },
  40: { min: 60, max: 71 },
  0: { min: genericFields.term.max }
};

export const defaultLeasingCalculatorInfo: LeasingCalculatorInfo = {
  interestRates: defaultInterestRate,
  residualValueCeiling: defaultResidualValueCeiling,
  genericFields: genericFields
};
