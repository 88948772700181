import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'components';
import { getTypesOfPartnership } from 'defaultConfiguration/getTypesOfPartnership';
import { clearToastMessages } from 'features/app/slice';
import { useLazyFetchApplicationDetails, useQueryFields } from 'features/application/hooks';
import {
  selectIsCompanyStepsEnabled,
  selectIsNewCompany,
  selectStepsSequenceByType
} from 'features/application/selectors';
import {
  setPartner,
  setTelem,
  setFieldsFromWebRedirect,
  setIsNewCompany,
  setStepsSequence,
  setActiveStep,
  setCompanyData,
  setCompletionIndex
} from 'features/application/slice';
import { selectActiveCompany } from 'features/applications/selectors';
import { useAppDispatch, useAppSelector } from 'hooks/redux/hooks';
import { AppService } from 'services';

import NewApplication from './newApplication';

type ApplicationMode = 'create' | 'edit';

const ApplicationWrapperPage = () => {
  const { capId } = useParams();

  const dispatch = useAppDispatch();

  const [queryPayload] = useQueryFields();

  const isNewCompany = useAppSelector(selectIsNewCompany);
  const isCompanyStepsEnabled = useAppSelector(selectIsCompanyStepsEnabled);
  const activeCompany = useAppSelector(selectActiveCompany);
  const steps = useAppSelector(selectStepsSequenceByType);

  const [mode, setMode] = useState<ApplicationMode>(capId ? 'edit' : 'create');
  const [initialized, setInitialized] = useState(false);

  const [fetchApplicationByIdAsync, { isUninitialized, isFetching, isLoading }] =
    useLazyFetchApplicationDetails();

  const loading = isUninitialized || isFetching || isLoading;

  useEffect(() => {
    const isRedirectFromWeb = !!queryPayload.dealId;

    const { partner, partnerType, telem, newcompany } = queryPayload || {};

    if (partner) {
      const typeOfPartnership = partnerType ?? getTypesOfPartnership(partner);

      if (!typeOfPartnership) {
        AppService.reportError({
          message: `Partner type was set without defined type of partnership: ${partner}`,
          eventId: Date.now().toString()
        });
      }

      dispatch(setPartner({ partner, partnerType: typeOfPartnership }));
    }

    if (telem) {
      dispatch(setTelem(telem));
    }

    if (isRedirectFromWeb && queryPayload.productSelect) {
      dispatch(setFieldsFromWebRedirect(queryPayload));
    }

    if (/true|1/.test(newcompany ?? 'false') || isNewCompany) {
      dispatch(setIsNewCompany(true));
    }

    setInitialized(true);
  }, [queryPayload, isNewCompany]);

  useEffect(() => {
    setMode(capId ? 'edit' : 'create');
  }, [capId]);

  useEffect(() => {
    if (capId) {
      dispatch(clearToastMessages());
      fetchApplicationByIdAsync({ capId });
    }
  }, [capId]);

  useEffect(() => {
    if (!initialized) {
      const isNewCompanyQueryParam = new URLSearchParams(window.location.search).get('newcompany');
      if (!isCompanyStepsEnabled && /false|0/.test(isNewCompanyQueryParam ?? 'false')) {
        dispatch(setCompanyData({ ...activeCompany, code: activeCompany?.id }));
      }

      dispatch(setStepsSequence(steps));
      dispatch(setActiveStep(steps[0]));
      dispatch(setCompletionIndex(-1));
    }
  }, [steps.join('')]);

  if ((mode === 'edit' && loading) || !setInitialized) {
    return <Loader opaque />;
  }

  return <NewApplication />;
};

export default ApplicationWrapperPage;
